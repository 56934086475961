import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import countries from '../../assets/json/countries.json';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class Kontakt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			claimNumber: '',
			isLoading: false,
			isSent: false,
			tabberTab: 1,
			emailError: true,
			disableSend: true,
			datenschutz: false,
			paymentCheck: false,
			paymentOption: 'iban',
			hasFiles: false,
			filesUploaded: [],
			info: {
				person1: {
					land: '',
					vorname: '',
					nachname: '',
					strasse: '',
					plz: '',
					ort: '',
					email: '',
					emailRepeat: '',
					telefon: '',
					bic: '',
					iban: '',
					paypalAccount: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					beleg1: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					beleg2: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					beleg3: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					beleg4: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false
				},
				person2: {
					vorname: '',
					nachname: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false,
					minderjaehrig: false
				},
				person3: {
					vorname: '',
					nachname: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false,
					minderjaehrig: false
				},
				person4: {
					vorname: '',
					nachname: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false,
					minderjaehrig: false
				},
				person5: {
					vorname: '',
					nachname: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false,
					minderjaehrig: false
				},
				person6: {
					vorname: '',
					nachname: '',
					ticket: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					bordkarten: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					sonstiges: {
						dataUri: '',
						fileName: '',
						fileType: '',
						fileExtension: '',
						file: ''
					},
					nichtUnterlagen: false,
					minderjaehrig: false
				}
			}
		}
	}

	changeTab = (tab) => {
		var allowTab = false;
		if (tab < this.state.tabberTab) {
			this.setState({
				tabberTab: tab
			});
		} else {
			if (this.state.tabberTab === 1) {
				if (this.state.info.person1.vorname === '' || this.state.info.person1.nachname === '' || this.state.info.person1.strasse === '' || this.state.info.person1.plz === '' || this.state.info.person1.ort === '' || this.state.info.person1.email === '' || this.state.emailError) {
					allowTab = false;
				} else {
					if (!this.state.info.person1.nichtUnterlagen) {
						if (this.state.info.person1.ticket.dataUri !== '' || this.state.info.person1.bordkarten.dataUri !== '' || this.state.info.person1.sonstiges.dataUri !== '') {
							allowTab = true;
						} else {
							allowTab = false;
						}
					} else {
						allowTab = true;
					}
				}
				if (this.state.paymentOption === 'iban') {
					if (this.state.info.person1.bic === '' || this.state.info.person1.iban === '') {
						allowTab = false;
					}
				} else {
					if (this.state.info.person1.paypalAccount === '') {
						allowTab = false;
					}
				}
				if (allowTab) {
					this.setState({
						tabberTab: tab
					});
				}
			} else {
				var currentTab = this.state.tabberTab;
				var person = 'person' + currentTab;
				if (this.state.info[person].vorname === '' && this.state.info[person].nachname === '') {
					return false;
				} else {
					if (this.state.info[person].vorname === '' && this.state.info[person].nachname !== '') {
						return false;
					} else if (this.state.info[person].vorname !== '' && this.state.info[person].nachname === '') {
						return false;
					} else {
						if (!this.state.info[person].nichtUnterlagen) {
							if (this.state.info[person].ticket.dataUri !== '' || this.state.info[person].bordkarten.dataUri !== '' || this.state.info[person].sonstiges.dataUri !== '') {
								this.setState({
									tabberTab: tab
								});
							} else {
								return false;
							}
						} else {
							this.setState({
								tabberTab: tab
							});
						}
					}
				}
			}
		}
	}

	validateEmail = (event) => {
		var email = event.target.value;
		if (email !== '') {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (event.target.name === 'email') {
				if (!re.test(String(email).toLowerCase())) {
					this.refs.email.style.color = '#FF0000';
					this.setState({
						emailError: true
					}, function () {
						this.reviewData();
					});
				} else {
					this.refs.email.style.color = '#666666';
					this.setState({
						emailError: false
					}, function () {
						this.reviewData();
					});
				}
			} else if (event.target.name === 'paypalAccount') {
				if (!re.test(String(email).toLowerCase())) {
					this.refs.paypalAccount.style.color = '#FF0000';
					this.setState({
						emailError: true
					}, function () {
						this.reviewData();
					});
				} else {
					this.refs.paypalAccount.style.color = '#666666';
					this.setState({
						emailError: false
					}, function () {
						this.reviewData();
					});
				}
			} else if (event.target.name === 'emailRepeat') {
				if (!re.test(String(email).toLowerCase())) {
					this.refs.emailRepeat.style.color = '#FF0000';
					this.setState({
						emailError: true
					}, function () {
						this.reviewData();
					});
				} else {
					this.refs.emailRepeat.style.color = '#666666';
					this.setState({
						emailError: false
					}, function () {
						this.reviewData();
					});
				}
			}
		} else {
			if (event.target.name === 'email') {
				this.refs.email.style.color = '#666666';
			} else if (event.target.name === 'paypalAccount') {
				this.refs.paypalAccount.style.color = '#666666';
			} else if (event.target.name === 'emailRepeat') {
				this.refs.emailRepeat.style.color = '#666666';
			}
			this.setState({
				emailError: false
			}, function () {
				this.reviewData();
			});
		}
	}

	validateEmailRepeat = (event) => {
		var email = event.target.value;
		if (email !== '' && email !== this.state.info.person1.email) {
			this.refs.emailRepeat.style.color = '#FF0000';
			this.setState({
				emailError: true
			}, function () {
				this.reviewData();
			});
		} else {
			this.refs.emailRepeat.style.color = '#666666';
			this.setState({
				emailError: false
			}, function () {
				this.reviewData();
			});
		}
	}

	onChangeNaN = (event) => {
		if (isNaN(event.target.value)) {
			return false;
		} else {
			var info = this.state.info;
			info['person1'][event.target.name] = event.target.value;
			this.setState({
				info: info
			}, function () {
				this.reviewData();
			});
		}
	}

	onChange = (event) => {
		var info = this.state.info;
		var currentTab = this.state.tabberTab;
		var person = 'person' + currentTab;
		info[person][event.target.name] = event.target.value;
		this.setState({
			info: info
		}, function () {
			this.reviewData();
		});
	}

	onCheck = (event) => {
		var info = this.state.info;
		var currentTab = this.state.tabberTab;
		var person = 'person' + currentTab;
		info[person][event.target.name] = !info[person][event.target.name];
		this.setState({
			info: info
		}, function () {
			this.reviewData();
		});
	}

	onCheckDatenschutz = () => {
		this.setState({
			datenschutz: !this.state.datenschutz
		}, function () {
			this.reviewData();
		});
	}

	onCheckPayment = () => {
		this.setState({
			paymentCheck: !this.state.paymentCheck
		}, function () {
			this.reviewData();
		});
	}

	onCheckFiles = (event) => {
		var info = this.state.info;
		var currentTab = this.state.tabberTab;
		var person = 'person' + currentTab;
		var filesUploaded = this.state.filesUploaded;
		if (filesUploaded.indexOf("ticket" + currentTab) === -1 && filesUploaded.indexOf("bordkarten" + currentTab) === -1 && filesUploaded.indexOf("sonstiges" + currentTab) === -1) {
			// Accept the changes
			info[person][event.target.name] = !info[person][event.target.name];
			this.setState({
				info: info
			}, function () {
				this.reviewData();
			});
		} else {
			// Prevent the checkbox from ticking, and tick it to false in case it is ticked
			info[person][event.target.name] = false;
			this.setState({
				info: info
			}, function () {
				this.reviewData();
			});
		}
	}

	onRadioChange = (event) => {
		this.setState({
			paymentOption: event.target.value
		}, function () {
			this.reviewData();
		});
	}

	onFileChange = (event) => {
		var eventName = event.target.name;
		var eventId = event.target.id;
		var reader = new FileReader();
		var file = event.target.files[0];
		var info = this.state.info;
		var currentTab = this.state.tabberTab;
		var person = 'person' + currentTab;
		reader.onload = (upload) => {
			info[person][eventName]['dataUri'] = upload.target.result;
			info[person][eventName]['fileName'] = file.name;
			info[person][eventName]['fileType'] = file.type;
			info[person][eventName]['fileExtension'] = file.name.match(/\.[0-9a-z]+$/i);
			info[person][eventName]['file'] = event.target.files;
			if (info[person][eventName]['fileType'] !== 'image/jpeg' && info[person][eventName]['fileType'] !== 'image/png' && info[person][eventName]['fileType'] !== 'application/pdf') {
				alert("Nur JPG-, PNG- und PDF-Dateien werden akzeptiert.");
				document.getElementById(eventId).value = '';
				return false;
			} else {
				var filesUploaded = this.state.filesUploaded;
				filesUploaded.push(eventId);
				info[person]['nichtUnterlagen'] = false;
				this.setState({
					info: info,
					hasFiles: true,
					filesUploaded: filesUploaded
				}, function () {
					this.reviewData();
				});
			}
		};
		reader.readAsDataURL(file);
	}

	reviewData = () => {
		var disableSend = true;
		if (this.state.emailError) {
			disableSend = true;
		} else if (!this.state.datenschutz) {
			disableSend = true;
		} else if (!this.state.paymentCheck) {
			disableSend = true;
		} else if (this.state.info.person1.email !== this.state.info.person1.emailRepeat) {
			disableSend = true;
		} else {
			if (this.state.info.person1.vorname === '' || this.state.info.person1.nachname === '' || this.state.info.person1.strasse === '' || this.state.info.person1.plz === '' || this.state.info.person1.ort === '' || this.state.info.person1.email === '') {
				disableSend = true;
			} else {
				if (!this.state.info.person1.nichtUnterlagen) {
					if (this.state.info.person1.ticket.dataUri !== '' || this.state.info.person1.bordkarten.dataUri !== '' || this.state.info.person1.sonstiges.dataUri !== '') {
						disableSend = false;
					} else {
						disableSend = true;
					}
				} else {
					disableSend = false;
				}
			}
			if (this.state.paymentOption === 'iban') {
				if (this.state.info.person1.bic === '' || this.state.info.person1.iban === '') {
					disableSend = true;
				}
			} else if (this.state.paymentOption === 'paypal') {
				if (this.state.info.person1.paypalAccount === '') {
					disableSend = true;
				}
			}
			var currentTab = this.state.tabberTab;
			var person = 'person' + currentTab;
			if (currentTab !== 1) {
				if (this.state.info[person].vorname === '' && this.state.info[person].nachname === '') {
					disableSend = false;
				} else {
					if (this.state.info[person].vorname === '' && this.state.info[person].nachname !== '') {
						disableSend = true;
					} else if (this.state.info[person].vorname !== '' && this.state.info[person].nachname === '') {
						disableSend = true;
					} else {
						if (!this.state.info[person].nichtUnterlagen) {
							if (this.state.info[person].ticket.dataUri !== '' || this.state.info[person].bordkarten.dataUri !== '' || this.state.info[person].sonstiges.dataUri !== '') {
								disableSend = false;
							} else {
								disableSend = true;
							}
						} else {
							disableSend = false;
						}
					}
				}
			}
		}
		this.setState({
			disableSend: disableSend
		});
	}

	submitForm = (event) => {

		// Cancel default
		event.preventDefault();

		// Start
		this.setState({
			isLoading: true
		}, async function () {

			// Variables
			var claimNumber = '';
			var fullYear = new Date().getFullYear();

			// Get latest claim number
			const cn = await directus.items('claims').readByQuery({
				limit: 1,
				sort: ['-date_created'],
				fields: ['claim_number']
			});
			const gn = await directus.items('gepaeck').readByQuery({
				limit: 1,
				sort: ['-date_created'],
				fields: ['claim_number']
			});

			// Combine arrays
			const combinedData = cn.data.concat(gn.data);

			// Conditions
			if (combinedData.length === 0) {

				// Start new
				claimNumber = 'CL-' + fullYear + '.110';

			} else {

				// Find the latest claim number from combined data
				const latestClaimNumber = combinedData.reduce((latest, item) => {
					const [prefix, yearNumber] = item.claim_number.split('-'); // e.g., 'CL-2023.110'
					const [year, number] = yearNumber.split('.').map(Number); // e.g., [2023, 110]
					const currentLatest = latest.split('-')[1].split('.').map(Number); // e.g., [2023, 110]
					if (year > currentLatest[0] || (year === currentLatest[0] && number > currentLatest[1])) {
						return item.claim_number;
					}
					return latest;
				}, 'CL-0.0'); // Initial value to compare with
			
				// Split the latest claim number
				var [_, latestYearNumber] = latestClaimNumber.split('-'); // '2023.110'
				var [year, number] = latestYearNumber.split('.').map(Number); // [2023, 110]
			
				// Determine the next claim number
				if (year === fullYear) {
					// Random number between 2 and 99 for new claim number
					var random = Math.floor(Math.random() * (99 - 2 + 1)) + 2;
					// If the latest claim number's year matches the current year, increment the number
					claimNumber = `CL-${fullYear}.${number + random}`;
				} else {
					// If the latest claim number's year does not match, start a new series for the current year
					claimNumber = 'CL-' + fullYear + '.110';
				}

			}

			// Create folder
			const folderID = await directus.items('directus_folders').createOne({
				parent: 'c6f845a5-e3e1-40af-8a88-1e9029f77fb1',
				name: claimNumber,
			});

			// Upload files
			const ticketp1 = this.state.info.person1.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_1', this.state.info.person1.ticket.file[0]) : '';
			const ticketp2 = this.state.info.person2.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_2', this.state.info.person2.ticket.file[0]) : '';
			const ticketp3 = this.state.info.person3.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_3', this.state.info.person3.ticket.file[0]) : '';
			const ticketp4 = this.state.info.person4.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_4', this.state.info.person4.ticket.file[0]) : '';
			const ticketp5 = this.state.info.person5.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_5', this.state.info.person5.ticket.file[0]) : '';
			const ticketp6 = this.state.info.person6.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket_person_6', this.state.info.person6.ticket.file[0]) : '';
			const bordkartenp1 = this.state.info.person1.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_1', this.state.info.person1.bordkarten.file[0]) : '';
			const bordkartenp2 = this.state.info.person2.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_2', this.state.info.person2.bordkarten.file[0]) : '';
			const bordkartenp3 = this.state.info.person3.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_3', this.state.info.person3.bordkarten.file[0]) : '';
			const bordkartenp4 = this.state.info.person4.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_4', this.state.info.person4.bordkarten.file[0]) : '';
			const bordkartenp5 = this.state.info.person5.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_5', this.state.info.person5.bordkarten.file[0]) : '';
			const bordkartenp6 = this.state.info.person6.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten_person_6', this.state.info.person6.bordkarten.file[0]) : '';
			const sonstigesp1 = this.state.info.person1.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_1', this.state.info.person1.sonstiges.file[0]) : '';
			const sonstigesp2 = this.state.info.person2.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_2', this.state.info.person2.sonstiges.file[0]) : '';
			const sonstigesp3 = this.state.info.person3.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_3', this.state.info.person3.sonstiges.file[0]) : '';
			const sonstigesp4 = this.state.info.person4.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_4', this.state.info.person4.sonstiges.file[0]) : '';
			const sonstigesp5 = this.state.info.person5.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_5', this.state.info.person5.sonstiges.file[0]) : '';
			const sonstigesp6 = this.state.info.person6.sonstiges.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_person_6', this.state.info.person6.sonstiges.file[0]) : '';
			const beleg1 = this.state.info.person1.beleg1.file !== '' ? await this.uploadFile(folderID.id, 'beleg_1', this.state.info.person1.beleg1.file[0]) : '';
			const beleg2 = this.state.info.person1.beleg2.file !== '' ? await this.uploadFile(folderID.id, 'beleg_2', this.state.info.person1.beleg2.file[0]) : '';
			const beleg3 = this.state.info.person1.beleg3.file !== '' ? await this.uploadFile(folderID.id, 'beleg_3', this.state.info.person1.beleg3.file[0]) : '';
			const beleg4 = this.state.info.person1.beleg4.file !== '' ? await this.uploadFile(folderID.id, 'beleg_4', this.state.info.person1.beleg4.file[0]) : '';

			// Clean state for directus
			let newState = {...this.state}; // Copy the state

			// Iterate over each person
			for(let i = 1; i <= 6; i++) {

				// Assign
				let person = newState.info[`person${i}`];

				// Remove the properties you don't need
				delete person.ticket;
				delete person.bordkarten;
				delete person.sonstiges;
				delete person.nichtUnterlagen;

				// For person1, also remove beleg1 to beleg4
				if(i === 1) {
					delete person.beleg1;
					delete person.beleg2;
					delete person.beleg3;
					delete person.beleg4;
				}

			}

			// Insert into database
			await directus.items('claims').createOne({
				folderID: folderID.id,
				claim_number: claimNumber,
				process_data: this.props.info,
				contact_data: newState,
				payment_type: this.state.paymentOption,
				iban: this.state.info.person1.iban,
				bic: this.state.info.person1.bic,
				paypal_address: this.state.info.person1.paypalAccount,
				files_person_1: {
					create: this.buildFiles([ticketp1, sonstigesp1, bordkartenp1, beleg1, beleg2, beleg3, beleg4]),
					update: [],
					delete: []
				},
				files_person_2: {
					create: this.buildFiles([ticketp2, sonstigesp2, bordkartenp2]),
					update: [],
					delete: []
				},
				files_person_3: {
					create: this.buildFiles([ticketp3, sonstigesp3, bordkartenp3]),
					update: [],
					delete: []
				},
				files_person_4: {
					create: this.buildFiles([ticketp4, sonstigesp4, bordkartenp4]),
					update: [],
					delete: []
				},
				files_person_5: {
					create: this.buildFiles([ticketp5, sonstigesp5, bordkartenp5]),
					update: [],
					delete: []
				},
				files_person_6: {
					create: this.buildFiles([ticketp6, sonstigesp6, bordkartenp6]),
					update: [],
					delete: []
				},
			});

			// Remove loader and change page
			window.location.replace('/prozess/ende');
			// this.setState({
			// 	isLoading: false,
			// 	isSent: true,
			// 	claimNumber: claimNumber
			// }, function () {
				
			// });

		});
		
	}

	// Build JSON for files uploaded
	buildFiles = (arr) => {

		// Loop files
		var finalArray = [];
		for (var i = 0; i < arr.length; i ++) {

			// Conditions
			if (arr[i] !== '') {

				// Add to array
				finalArray.push({
					claims_id: '+',
					directus_files_id: {
						id: arr[i],
					}
				});

			}

		}

		// Return array
		return finalArray;

	}

	// Upload a file and return the ID
	uploadFile = (folderID, name, file) => {

		// New promise
		return new Promise(async (resolve) => {

			// Create form data
			var formData = new FormData();

			// Append information
			formData.append('folder', folderID);
			formData.append(name, file);

			// Upload file
			await directus.files.createOne(formData).then((res) => {

				// Resolve file ID
				resolve(res.id);

			}).catch((err) => {

				// Log error
				console.log(err);

			});

		});

	}

	render() {
		return (
			<div className={'anim1 step visible'}>
				<h2>Kontakt-Informationen</h2>
				<div className="tabber">
					<ul className="tabberNav">
						<li className={'anim1' + (this.state.tabberTab === 1 ? ' active' : '')} onClick={() => this.changeTab(1)}>
							<span className="fas fa-user fa-fw"></span>
							1
						</li>
						<li className={'anim1' + (this.state.tabberTab === 2 ? ' active' : '')} onClick={() => this.changeTab(2)}>
							<span className="fas fa-user fa-fw"></span>
							2
						</li>
						<li className={'anim1' + (this.state.tabberTab === 3 ? ' active' : '')} onClick={() => this.changeTab(3)}>
							<span className="fas fa-user fa-fw"></span>
							3
						</li>
						<li className={'anim1' + (this.state.tabberTab === 4 ? ' active' : '')} onClick={() => this.changeTab(4)}>
							<span className="fas fa-user fa-fw"></span>
							4
						</li>
						<li className={'anim1' + (this.state.tabberTab === 5 ? ' active' : '')} onClick={() => this.changeTab(5)}>
							<span className="fas fa-user fa-fw"></span>
							5
						</li>
						<li className={'anim1' + (this.state.tabberTab === 6 ? ' active' : '')} onClick={() => this.changeTab(6)}>
							<span className="fas fa-user fa-fw"></span>
							6
						</li>
					</ul>
					<div className="tabberContent">
						<div style={{ display: (this.state.tabberTab === 1) ? 'block' : 'none' }}>
							<input type="text" name="vorname" placeholder="Vorname *" className="third" onChange={this.onChange} value={this.state.info.person1.vorname} />
							<input type="text" name="nachname" placeholder="Nachname *" className="third" onChange={this.onChange} value={this.state.info.person1.nachname} />
							<select name="land" className="third" onChange={this.onChange} value={this.state.info.person1.land} style={{ marginRight: 0 }}>
								<option value="">Land *</option>
								<optgroup>
									<option value="DE">Deutschland</option>
									<option value="AT">Österreich</option>
									<option value="CH">Schweiz</option>
								</optgroup>
								<optgroup>
									{/* Map JSON */}
									{countries.countries.map((val, i) => {
										return <option value={val.code} key={Math.random()}>{val.name}</option>;
									})}
								</optgroup>
							</select>
							<input type="text" name="strasse" placeholder="Straße und Hausnummer *" className="third" onChange={this.onChange} value={this.state.info.person1.strasse} />
							<input type="text" name="plz" placeholder="PLZ *" maxLength={7} onChange={this.onChange} value={this.state.info.person1.plz} className="third" />
							<input type="text" name="ort" placeholder="Ort *" className="third" onChange={this.onChange} value={this.state.info.person1.ort} style={{ marginRight: 0 }} />
							<input type="email" ref="email" name="email" placeholder="E-Mail *" className="third" onChange={this.onChange} value={this.state.info.person1.email} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
							<input type="email" ref="emailRepeat" name="emailRepeat" placeholder="E-Mail Adresse bestätigen *" className="third" onChange={this.onChange} value={this.state.info.person1.emailRepeat} onKeyUp={this.validateEmailRepeat} onBlur={this.validateEmailRepeat} onFocus={this.validateEmailRepeat} />
							<input type="tel" name="telefon" placeholder="Telefon" className="third" style={{ marginRight: 0 }} onChange={this.onChange} value={this.state.info.person1.telefon} />
							<div className="inputRadioCtr">
								<input type="radio" value="iban" checked={this.state.paymentOption === 'iban'} onChange={this.onRadioChange} /> IBAN
							</div>
							<div className="inputRadioCtr" style={{ marginRight: 0 }}>
								<input type="radio" value="paypal" checked={this.state.paymentOption === 'paypal'} onChange={this.onRadioChange} /> PayPal
							</div>
							<span style={{ marginLeft: 35 }}>Auf dieses Konto zahlen wir die Entschädigung aus.</span>
							<div style={{ display: (this.state.paymentOption === 'iban' ? 'block' : 'none') }}>
								<MaskedInput mask="AA11 1111 1111 1111 1111 11" name="iban" placeholder="IBAN *" className="half" style={{ marginRight: '0.7%' }} maxLength={24} onChange={this.onChange} value={this.state.info.person1.iban} />
								<MaskedInput mask="###########" name="bic" placeholder="BIC *" className="half" style={{ marginLeft: '0.7%' }} maxLength={15} onChange={this.onChange} value={this.state.info.person1.bic} />
							</div>
							<div style={{ display: (this.state.paymentOption === 'paypal' ? 'block' : 'none') }}>
								<input type="email" ref="paypalAccount" name="paypalAccount" placeholder="PayPal-Konto *" onChange={this.onChange} value={this.state.info.person1.paypalAccount} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket1">{this.state.info.person1.ticket?.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person1.ticket?.fileName}</label>
								<input name="ticket" type="file" id="ticket1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten1">{this.state.info.person1.bordkarten?.fileName === '' ? 'Bordkarten' : this.state.info.person1.bordkarten?.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges1">{this.state.info.person1.sonstiges?.fileName === '' ? 'Sonstiges' : this.state.info.person1.sonstiges?.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>

							{/* Additional costs (new) */}
							{
								this.props.info.additionalCosts === 'Ja' ?
									<>
										<div className="inputFileCtr" style={{ width: '49.2%' }}>
											<span className="fas fa-paperclip"></span>
											<label htmlFor="beleg1">{this.state.info.person1.beleg1.fileName === '' ? 'Beleg 1' : this.state.info.person1.beleg1.fileName}</label>
											<input name="beleg1" type="file" id="beleg1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
										</div>
										<div className="inputFileCtr" style={{ width: '49.2%', marginRight: 0 }}>
											<span className="fas fa-paperclip"></span>
											<label htmlFor="beleg2">{this.state.info.person1.beleg2.fileName === '' ? 'Beleg 2' : this.state.info.person1.beleg2.fileName}</label>
											<input name="beleg2" type="file" id="beleg2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
										</div>
										<div className="inputFileCtr" style={{ width: '49.2%' }}>
											<span className="fas fa-paperclip"></span>
											<label htmlFor="beleg3">{this.state.info.person1.beleg3.fileName === '' ? 'Beleg 3' : this.state.info.person1.beleg3.fileName}</label>
											<input name="beleg3" type="file" id="beleg3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
										</div>
										<div className="inputFileCtr" style={{ width: '49.2%', marginRight: 0 }}>
											<span className="fas fa-paperclip"></span>
											<label htmlFor="beleg4">{this.state.info.person1.beleg4.fileName === '' ? 'Beleg 4' : this.state.info.person1.beleg4.fileName}</label>
											<input name="beleg4" type="file" id="beleg4" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
										</div>
									</>
									: null
							}

							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person1.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"><button className="anim1" onClick={() => this.changeTab(2)}>Mitreisende</button></div>
							</div>
						</div>
						<div style={{ display: (this.state.tabberTab === 2) ? 'block' : 'none' }}>
							<input name="minderjaehrig" style={{ display: 'inline-block', marginRight: 10, width: 'auto' }} type="checkbox" value={this.state.info.person2.minderjaehrig} onChange={this.onCheck} /> <span style={{ fontSize: 14 }} className="s-notAlign">Mitreisender ist minderjährig</span><br /><br />
							<input type="text" name="vorname" placeholder="Vorname" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.info.person2.vorname} />
							<input type="text" name="nachname" placeholder="Nachname" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.info.person2.nachname} />
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket2">{this.state.info.person2.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person2.ticket.fileName}</label>
								<input name="ticket" type="file" id="ticket2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten2">{this.state.info.person2.bordkarten.fileName === '' ? 'Bordkarten' : this.state.info.person2.bordkarten.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges2">{this.state.info.person2.sonstiges.fileName === '' ? 'Sonstiges' : this.state.info.person2.sonstiges.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person2.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"><button className="anim1" onClick={() => this.changeTab(3)}>Weiterer Mitreisender</button></div>
							</div>
						</div>
						<div style={{ display: (this.state.tabberTab === 3) ? 'block' : 'none' }}>
							<input name="minderjaehrig" style={{ display: 'inline-block', marginRight: 10, width: 'auto' }} type="checkbox" value={this.state.info.person3.minderjaehrig} onChange={this.onCheck} /> <span style={{ fontSize: 14 }}>Mitreisender ist minderjährig</span><br /><br />
							<input type="text" name="vorname" placeholder="Vorname" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.info.person3.vorname} />
							<input type="text" name="nachname" placeholder="Nachname" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.info.person3.nachname} />
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket3">{this.state.info.person3.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person3.ticket.fileName}</label>
								<input name="ticket" type="file" id="ticket3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten3">{this.state.info.person3.bordkarten.fileName === '' ? 'Bordkarten' : this.state.info.person3.bordkarten.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges3">{this.state.info.person3.sonstiges.fileName === '' ? 'Sonstiges' : this.state.info.person3.sonstiges.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person3.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"><button className="anim1" onClick={() => this.changeTab(4)}>Weiterer Mitreisender</button></div>
							</div>
						</div>
						<div style={{ display: (this.state.tabberTab === 4) ? 'block' : 'none' }}>
							<input name="minderjaehrig" style={{ display: 'inline-block', marginRight: 10, width: 'auto' }} type="checkbox" value={this.state.info.person4.minderjaehrig} onChange={this.onCheck} /> <span style={{ fontSize: 14 }}>Mitreisender ist minderjährig</span><br /><br />
							<input type="text" name="vorname" placeholder="Vorname" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.info.person4.vorname} />
							<input type="text" name="nachname" placeholder="Nachname" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.info.person4.nachname} />
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket4">{this.state.info.person4.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person4.ticket.fileName}</label>
								<input name="ticket" type="file" id="ticket4" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten4">{this.state.info.person4.bordkarten.fileName === '' ? 'Bordkarten' : this.state.info.person4.bordkarten.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten4" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges4">{this.state.info.person4.sonstiges.fileName === '' ? 'Sonstiges' : this.state.info.person4.sonstiges.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges4" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person4.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"><button className="anim1" onClick={() => this.changeTab(5)}>Weiterer Mitreisender</button></div>
							</div>
						</div>
						<div style={{ display: (this.state.tabberTab === 5) ? 'block' : 'none' }}>
							<input name="minderjaehrig" style={{ display: 'inline-block', marginRight: 10, width: 'auto' }} type="checkbox" value={this.state.info.person5.minderjaehrig} onChange={this.onCheck} /> <span style={{ fontSize: 14 }}>Mitreisender ist minderjährig</span><br /><br />
							<input type="text" name="vorname" placeholder="Vorname" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.info.person5.vorname} />
							<input type="text" name="nachname" placeholder="Nachname" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.info.person5.nachname} />
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket5">{this.state.info.person5.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person5.ticket.fileName}</label>
								<input name="ticket" type="file" id="ticket5" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten5">{this.state.info.person5.bordkarten.fileName === '' ? 'Bordkarten' : this.state.info.person5.bordkarten.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten5" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges5">{this.state.info.person5.sonstiges.fileName === '' ? 'Sonstiges' : this.state.info.person5.sonstiges.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges5" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person5.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"><button className="anim1" onClick={() => this.changeTab(6)}>Weiterer Mitreisender</button></div>
							</div>
						</div>
						<div style={{ display: (this.state.tabberTab === 6) ? 'block' : 'none' }}>
							<input name="minderjaehrig" style={{ display: 'inline-block', marginRight: 10, width: 'auto' }} type="checkbox" value={this.state.info.person6.minderjaehrig} onChange={this.onCheck} /> <span style={{ fontSize: 14 }}>Mitreisender ist minderjährig</span><br /><br />
							<input type="text" name="vorname" placeholder="Vorname" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.info.person6.vorname} />
							<input type="text" name="nachname" placeholder="Nachname" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.info.person6.nachname} />
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="ticket6">{this.state.info.person6.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.info.person6.ticket.fileName}</label>
								<input name="ticket" type="file" id="ticket6" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr">
								<span className="fas fa-paperclip"></span>
								<label htmlFor="bordkarten6">{this.state.info.person6.bordkarten.fileName === '' ? 'Bordkarten' : this.state.info.person6.bordkarten.fileName}</label>
								<input name="bordkarten" type="file" id="bordkarten6" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="inputFileCtr" style={{ marginRight: 0 }}>
								<span className="fas fa-paperclip"></span>
								<label htmlFor="sonstiges6">{this.state.info.person6.sonstiges.fileName === '' ? 'Sonstiges' : this.state.info.person6.sonstiges.fileName}</label>
								<input name="sonstiges" type="file" id="sonstiges6" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
							</div>
							<div className="row">
								<div className="col-6" style={{ fontSize: 14 }}>
									<input style={{ display: 'inline-block', marginRight: 10, marginTop: 13, width: 'auto' }} type="checkbox" name="nichtUnterlagen" checked={this.state.info.person6.nichtUnterlagen} onChange={this.onCheckFiles} /> Unterlagen habe ich gerade nicht zur Hand
								</div>
								<div className="col-6"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="paymentCheck">
					<input type="checkbox" name="paymentCheck" value={this.state.paymentCheck} onChange={this.onCheckPayment} style={{ display: 'inline-block' }} /> Ich habe meine <HashLink target="_blank" to="/agb#4-2" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Kontodaten</HashLink> für den Geldempfang nochmals überprüft und bestätige, dass diese so korrekt sind.
				</div>
				<div className="datenschutzCheck" style={{ paddingTop: 10 }}>
					<input type="checkbox" name="datenschutz" value={this.state.datenschutz} onChange={this.onCheckDatenschutz} style={{ display: 'inline-block' }} /> Ich habe die <Link target="_blank" to="/datenschutz">Datenschutzerklärung</Link> gelesen und stimme der Weiterverarbeitung meiner Daten zur Bearbeitung meines Anliegens zu.
				</div>
				<button className={'cta1 anim1' + (this.state.disableSend || this.state.isLoading ? ' disabled' : '')} disabled={this.state.disableSend || this.state.isLoading ? true : false} onClick={this.submitForm}>Senden <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>
			</div>
		);
	}
}

export default Kontakt;
