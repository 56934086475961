import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import BgImage from '../../assets/bg-datenschutz.jpg';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

class AGB extends Component {
	constructor(props) {
		super(props);
		this.state = {
			agbContent: '',
		}
	}

	async componentDidMount() {

		// Connect to Directus
		const directus = new Directus(apiUrl);

		// Get list
		const agbContent  = await directus.items('page_agb').readByQuery({ fields: ['*'] });

		// Assign
		this.setState({
			agbContent: agbContent.data.content,
		});

	}

    render() {
    	return(
    		<div>
                <Helmet>
                    <title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - AGB</title>
                    <meta name="fragment" content="!" />
                    <meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
                    <meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
                </Helmet>
    			<Header section="AGB" />
    			<div id="fullContainer" style={{ backgroundImage: 'url(' + BgImage + ')' }}>
    				<div className="text" dangerouslySetInnerHTML={{ __html: this.state.agbContent }}></div>
				</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default AGB;
