import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import DateTime from 'react-datetime';
import '../../../DateTime.css';
import Header from '../../structure/Header';
import Footer from '../../structure/Footer';
import FlightInfo from '../../views/FlightInfo';
import LinkButton from '../../elements/LinkButton';
import KontaktProzess from '../../views/KontaktProzess';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../../constants/server';

require('moment/locale/de');

// Connect to Directus
const directus = new Directus(apiUrl);

class NichtMitfliegen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep: 1,
			problemOption: 'Ich durfte nicht mitfliegen',
			denyReason: '',
			flightNumber: '',
			flightDate: '',
			flightNumberConnection: '',
			flightDateConnection: '',
			flightNumberAlternative: '',
			flightDateAlternative: '',
			flightNumberAlternative2: '',
			flightDateAlternative2: '',
			flightNumberAlternative3: '',
			flightDateAlternative3: '',
			isLoading: false,
			checkFlightBtnDisabled: false,
			checkFlightBtnDisabledConnection: false,
			checkFlightBtnDisabledAlternative: false,
			checkFlightBtnDisabledAlternative2: false,
			checkFlightBtnDisabledAlternative3: false,
			flightInfo: {},
			flightInfoConnection: {},
			flightInfoAlternative: {},
			flightInfoAlternative2: {},
			flightInfoAlternative3: {},
			isReadyFlightInfo: false,
			isReadyFlightInfoConnection: false,
			isReadyFlightInfoAlternative: false,
			isReadyFlightInfoAlternative2: false,
			isReadyFlightInfoAlternative3: false,
			errorFlightInfo: false,
			errorFlightInfoConnection: false,
			errorFlightInfoAlternative: false,
			errorFlightInfoAlternative2: false,
			errorFlightInfoAlternative3: false,
			denyInformationField: '',
			flightCancelInfo: '',
			daysResult: 0,
			hoursArrivalResult: 0,
			hoursDepartureResult: 0,
			errorFlightInfoMessage: '',
			errorFlightInfoConnectionMessage: '',
			errorFlightInfoAlternativeMessage: '',
			errorFlightInfoAlternative2Message: '',
			errorFlightInfoAlternative3Message: '',
			isValidPastDate: function(){},
			isValidOtherDate: function(){},
			errorCode: 0,
			contactedAirline: '',
			contactedAirlineField: '',
			additionalCosts: '',
			additionalCostsField: '',
			latestOriginalFlight: '',
			latestReplacementFlight: ''
		}
	}

	componentDidMount() {
		var validDate = function(current) {
			return current.isBefore(moment()) && current.isAfter(moment().subtract(3, 'year'));
		};
		this.setState({
			isValidPastDate: validDate
		});
		validDate = function(current) {
			return current.isBefore(moment().add(2, 'day'));
		};
		this.setState({
			isValidOtherDate: validDate
		});
	}

	jumpStep = (step) => {
		this.setState({
			currentStep: step
		});
	}

	selectDenyReason = (reason) => {
		if(reason === 'Kein Grund genannt') {
			this.setState({
				denyInformationField: ''
			});
		}
		this.setState({
			denyReason: reason
		});
	}

	selectContactedAirline = (option) => {
		this.setState({
			contactedAirline: option
		});
	}

	selectAdditionalCosts = (option) => {
		this.setState({
			additionalCosts: option
		});
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	onChangeUC = (event) => {
		var val = event.target.value.toUpperCase();
		this.setState({
			[event.target.name]: val
		});
	}

	onChangeDate = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDate: format
		});
	}

	onChangeDateCancel = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightCancelInfo: format
		}, function() {
			this.reviewDates();
		});
	}

	onChangeDateConnection = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateConnection: format
		});
	}

	onChangeDateAlternative = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative: format
		});
	}

	onChangeDateAlternative2 = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative2: format
		});
	}

	onChangeDateAlternative3 = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative3: format
		});
	}

	getFlightInfo = () => {
		var self = this;
		this.setState({
			isLoading: true,
			checkFlightBtnDisabled: true,
			errorFlightInfo: false
		}, function() {
			directus.transport.post('/flights/check', {
				fn: this.state.flightNumber,
				fd: this.state.flightDate,
				pi: this.state.problemOption,
				type: 0
			}).then((response) => {
				self.setState({
					isLoading: false,
					checkFlightBtnDisabled: false,
					currentStep: 2,
					flightInfo: response.raw,
					latestOriginalFlight: 'flightInfo'
				}, function() {
					self.setState({
						isReadyFlightInfo: true
					});
				});
			}).catch((error) => {
				self.setState({
					errorCode: 1,
					isLoading: false,
					checkFlightBtnDisabled: false,
					errorFlightInfo: true,
					errorFlightInfoMessage: error.errors[0].message,
				});
			});
		});
	}

	getFlightInfoConnection = () => {
		var self = this;
		if(this.state.flightNumberConnection === '' || this.state.flightDateConnection === '') {
			this.setState({
				currentStep: 6
			});
		} else {
			this.setState({
				isLoading: true,
				checkFlightBtnDisabledConnection: true
			}, function() {
				directus.transport.post('/flights/check', {
					fn: this.state.flightNumberConnection,
					fd: this.state.flightDateConnection,
					departureInfo: this.state.flightInfo.departureAirport,
					opt: 'noCheck',
					type: 0
				}).then((response) => {
					self.setState({
						isLoading: false,
						checkFlightBtnDisabledConnection: false,
						currentStep: 5,
						flightInfoConnection: response.raw,
						latestOriginalFlight: 'flightInfoConnection'
					}, function() {
						self.setState({
							isReadyFlightInfoConnection: true
						});
					});
				}).catch((error) => {
					self.setState({
						errorCode: 1,
						isLoading: false,
						checkFlightBtnDisabledConnection: false,
						errorFlightInfoConnection: true,
						errorFlightInfoConnectionMessage: error.errors[0].message,
					});
				});
			});
		}
	}

	getFlightInfoAlternative = () => {
		var self = this;
		if(this.state.flightNumberAlternative === '' || this.state.flightDateAlternative === '') {
			this.setState({
				currentStep: 12,
				hoursArrivalResult: 300,
				hoursDepartureResult: 300
			});
		} else {
			this.setState({
				isLoading: true,
				checkFlightBtnDisabledAlternative: true
			}, function() {
				directus.transport.post('/flights/check', {
					fn: this.state.flightNumberAlternative,
					fd: this.state.flightDateAlternative,
					departureInfo: this.state.flightInfo.departureAirport,
					opt: 'noCheck',
					type: 0
				}).then((response) => {
					self.setState({
						isLoading: false,
						checkFlightBtnDisabledAlternative: false,
						currentStep: 7,
						flightInfoAlternative: response.raw,
						latestReplacementFlight: 'flightInfoAlternative'
					}, function() {
						self.setState({
							isReadyFlightInfoAlternative: true
						}, function() {
							var afa = moment(self.state.flightInfo.operationalTimes.scheduledGateArrival.dateLocal);
							var afd = moment(self.state.flightInfo.operationalTimes.scheduledGateDeparture.dateLocal);
							var rfa = moment(self.state.flightInfoAlternative.operationalTimes.scheduledGateArrival.dateLocal);
							var rfd = moment(self.state.flightInfoAlternative.operationalTimes.scheduledGateDeparture.dateLocal);
							var fd = moment(self.state.flightDate, "DD.MM.YYYY");
							var fcd = moment(self.state.flightDateAlternative, "DD.MM.YYYY");
							var days = fcd.diff(fd, 'days');
							if(days > 0) {
								self.setState({
									hoursArrivalResult: 300,
									hoursDepartureResult: 300
								});
							} else {
								self.setState({
									hoursArrivalResult: rfa.diff(afa, 'minutes'),
									hoursDepartureResult: afd.diff(rfd, 'minutes')
								});
							}
						});
					});
				}).catch((error) => {
					self.setState({
						errorCode: 1,
						isLoading: false,
						checkFlightBtnDisabledAlternative: false,
						errorFlightInfoAlternative: true,
						errorFlightInfoAlternativeMessage: error.errors[0].message,
					});
				});
			});
		}
	}

	getFlightInfoAlternative2 = () => {
		var self = this;
		if(this.state.flightNumberAlternative2 === '' || this.state.flightDateAlternative2 === '') {
			this.setState({
				currentStep: 12
			});
		} else {
			this.setState({
				isLoading: true,
				checkFlightBtnDisabledAlternative2: true
			}, function() {
				directus.transport.post('/flights/check', {
					fn: this.state.flightNumberAlternative2,
					fd: this.state.flightDateAlternative2,
					departureInfo: this.state.flightInfo.departureAirport,
					opt: 'noCheck',
					type: 0
				}).then((response) => {
					self.setState({
						isLoading: false,
						checkFlightBtnDisabledAlternative2: false,
						currentStep: 9,
						flightInfoAlternative2: response.raw,
						latestReplacementFlight: 'flightInfoAlternative2'
					}, function() {
						self.setState({
							isReadyFlightInfoAlternative: true
						}, function() {
							var afa = moment(self.state.flightInfo.operationalTimes.scheduledGateArrival.dateLocal);
							var afd = moment(self.state.flightInfo.operationalTimes.scheduledGateDeparture.dateLocal);
							var rfa = moment(self.state.flightInfoAlternative2.operationalTimes.scheduledGateArrival.dateLocal);
							var rfd = moment(self.state.flightInfoAlternative2.operationalTimes.scheduledGateDeparture.dateLocal);
							var fd = moment(self.state.flightDate, "DD.MM.YYYY");
							var fcd = moment(self.state.flightDateAlternative2, "DD.MM.YYYY");
							var days = fcd.diff(fd, 'days');
							if(days > 0) {
								self.setState({
									hoursArrivalResult: 300,
									hoursDepartureResult: 300
								});
							} else {
								self.setState({
									hoursArrivalResult: rfa.diff(afa, 'minutes'),
									hoursDepartureResult: afd.diff(rfd, 'minutes')
								});
							}
						});
					});
				}).catch((error) => {
					self.setState({
						errorCode: 1,
						isLoading: false,
						checkFlightBtnDisabledAlternative2: false,
						errorFlightInfoAlternative2: true,
						errorFlightInfoAlternative2Message: error.errors[0].message,
					});
				});
			});
		}
	}

	getFlightInfoAlternative3 = () => {
		var self = this;
		if(this.state.flightNumberAlternative3 === '' || this.state.flightDateAlternative3 === '') {
			this.setState({
				currentStep: 12
			});
		} else {
			this.setState({
				isLoading: true,
				checkFlightBtnDisabledAlternative3: true
			}, function() {
				directus.transport.post('/flights/check', {
					fn: this.state.flightNumberAlternative3,
					fd: this.state.flightDateAlternative3,
					departureInfo: this.state.flightInfo.departureAirport,
					opt: 'noCheck',
					type: 0
				}).then((response) => {
					self.setState({
						isLoading: false,
						checkFlightBtnDisabledAlternative3: false,
						currentStep: 11,
						flightInfoAlternative3: response.raw,
						latestReplacementFlight: 'flightInfoAlternative3'
					}, function() {
						self.setState({
							isReadyFlightInfoAlternative: true
						}, function() {
							var afa = moment(self.state.flightInfo.operationalTimes.scheduledGateArrival.dateLocal);
							var afd = moment(self.state.flightInfo.operationalTimes.scheduledGateDeparture.dateLocal);
							var rfa = moment(self.state.flightInfoAlternative3.operationalTimes.scheduledGateArrival.dateLocal);
							var rfd = moment(self.state.flightInfoAlternative3.operationalTimes.scheduledGateDeparture.dateLocal);
							var fd = moment(self.state.flightDate, "DD.MM.YYYY");
							var fcd = moment(self.state.flightDateAlternative3, "DD.MM.YYYY");
							var days = fcd.diff(fd, 'days');
							if(days > 0) {
								self.setState({
									hoursArrivalResult: 300,
									hoursDepartureResult: 300
								});
							} else {
								self.setState({
									hoursArrivalResult: rfa.diff(afa, 'minutes'),
									hoursDepartureResult: afd.diff(rfd, 'minutes')
								});
							}
						});
					});
				}).catch((error) => {
					self.setState({
						errorCode: 1,
						isLoading: false,
						checkFlightBtnDisabledAlternative3: false,
						errorFlightInfoAlternative3: true,
						errorFlightInfoAlternative3Message: error.errors[0].message,
					});
				});
			});
		}
	}

    reviewDates = () => {
    	var fd = moment(this.state.flightDate);
    	var fcd = moment(this.state.flightCancelInfo);
    	this.setState({
    		daysResult: fd.diff(fcd, 'days')
    	});
    }

	processBack = () => {
		// Check current step
		if(this.state.currentStep === 1) {
			// Go to home
			this.props.history.replace('/');
		} else {
			// Navigate a step before
			this.setState({
				currentStep: this.state.currentStep - 1
			});
		}
	}

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Nicht Mitfliegen</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
				{/* Back button */}
				<button
					id="backButton"
					className="anim1"
					onClick={this.processBack}
				>
					<span className="fas fa-chevron-left"></span> Zurück
				</button>
    			<Header section="Nicht Mitfliegen" />
    			<div id="process">
    				{/* STEP 1 */}
    				<div id="step1" className={'anim2 step' + (this.state.currentStep === 1 ? ' visible' : '')}>
    					<h2>Wie lautet Ihre Flugnummer und Ihr Flugdatum?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={10} name="flightNumber" value={this.state.flightNumber} onChange={this.onChangeUC} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDate, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidPastDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Flugdatum', className: 'anim1', name: 'flightDate', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDate}
		    					/>
	    					</div>
	    					{this.state.errorCode === 1 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton><LinkButton className="cta1 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen" style={{ display: 'inline-block' }}>Weiter mit manueller Eingabe</LinkButton></div> : this.state.errorCode === 2 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton></div> : <button className={'cta1 anim1' + (this.state.flightNumber === '' || this.state.flightDate === '' || this.state.checkFlightBtnDisabled ? ' disabled' : '')} disabled={this.state.flightNumber === '' || this.state.flightDate === '' || this.state.checkFlightBtnDisabled ? true : false} onClick={this.getFlightInfo}>Weiter <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>}
	    					{this.state.errorFlightInfo ? <p className="response">{this.state.errorFlightInfoMessage}</p> : null}
    					</div>
    				</div>
    				{/* STEP 2 */}
    				<div id="step2" className={'anim2 step' + (this.state.currentStep === 2 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					{this.state.isReadyFlightInfo ? <FlightInfo flightInfo={this.state.flightInfo} flightNumber={this.state.flightNumber} /> : null }
    					{this.state.isReadyFlightInfo && this.state.flightInfo.compensationAllowed ? 
    						<div>
    							{/* <p className="response" style={{ color: '#47B749', fontSize: '20px' }}>{this.state.flightNumberAlternative === '' ? this.state[this.state.latestOriginalFlight].distanceMessage : this.state[this.state.latestReplacementFlight].distanceMessage}</p> */}
								<button className="cta1 anim1" onClick={() => this.jumpStep(3)}>Dies ist mein Flug</button>
								<LinkButton className="cta2 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen">Mein Flug wird nicht angezeigt</LinkButton>
							</div> : this.state.isReadyFlightInfo && !this.state.flightInfo.compensationAllowed ?
							<div>
								<p className="response">{this.state.flightInfo.denialReason}</p>
								<LinkButton className="cta1 anim1" to="/">Neue Anfrage</LinkButton>
							</div> : null
						}
    				</div>
    				{/* STEP 3 */}
    				<div id="step3" className={'anim2 step' + (this.state.currentStep === 3 ? ' visible' : '')}>
    					<h2>Aus welchem Grund durften Sie nicht mitfliegen?</h2>
    					<div className="options">
	    					<button className={'anim1 option' + (this.state.denyReason === 'Überbuchung' ? ' selected' : '')} onClick={() => this.selectDenyReason('Überbuchung')}><span className="anim1 fas fa-users"></span> Überbuchung</button>
	    					<button className={'anim1 option' + (this.state.denyReason === 'Kein Grund genannt' ? ' selected' : '')} onClick={() => this.selectDenyReason('Kein Grund genannt')}><span className="anim1 fas fa-comment-slash"></span> Kein Grund genannt</button>
	    					<button className={'anim1 option' + (this.state.denyReason === 'Anderer' ? ' selected' : '')} onClick={() => this.selectDenyReason('Anderer')}><span className="anim1 fas fa-question-circle"></span> Anderer</button>
    					</div>
    					<textarea className="anim1" name="denyInformationField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.denyInformationField} readOnly={this.state.denyReason === 'Kein Grund genannt' ? true : false}></textarea>
    					<div>
							<button className={'cta1 anim1' + (this.state.denyReason === 'Kein Grund genannt' ? '' : this.state.denyReason === '' || this.state.denyInformationField === '' ? ' disabled' : '')} disabled={(this.state.denyReason === 'Kein Grund genannt' ? false : this.state.denyReason === '' || this.state.denyInformationField === '' ? true : false)} onClick={() => this.jumpStep(4)}>Weiter</button>
						</div>
    				</div>
    				{/* STEP 4 */}
    				<div id="step4" className={'anim2 step' + (this.state.currentStep === 4 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Anschlussflug?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={10} name="flightNumberConnection" value={this.state.flightNumberConnection} onChange={this.onChangeUC} placeholder="Anschlussflugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateConnection, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Anschlussflugdatum', className: 'anim1', name: 'flightDateConnection', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateConnection}
		    					/>
	    					</div>
	    					{this.state.errorCode === 1 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton><LinkButton className="cta1 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen" style={{ display: 'inline-block' }}>Weiter mit manueller Eingabe</LinkButton></div> : <button className={'cta1 anim1' + (this.state.checkFlightBtnDisabledConnection ? ' disabled' : '')} disabled={this.state.checkFlightBtnDisabledConnection} onClick={this.getFlightInfoConnection}>{this.state.flightNumberConnection === '' || this.state.flightDateConnection === '' ? 'Weiter ohne Anschlussflug' : 'Weiter'} <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>}
	    					{this.state.errorFlightInfoConnection ? <p className="response">{this.state.errorFlightInfoConnectionMessage}</p> : null}
    					</div>
    				</div>
    				{/* STEP 5 */}
    				<div id="step5" className={'anim2 step' + (this.state.currentStep === 5 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					{this.state.isReadyFlightInfoConnection ? <FlightInfo flightInfo={this.state.flightInfoConnection} flightNumber={this.state.flightNumberConnection} /> : null }
    					<button className="cta1 anim1" onClick={() => this.jumpStep(6)}>Dies ist mein Flug</button>
    					<LinkButton className="cta2 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen">Mein Flug wird nicht angezeigt</LinkButton>
    				</div>
					{/* STEP 6 */}
    				<div id="step6" className={'anim2 step' + (this.state.currentStep === 6 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={10} name="flightNumberAlternative" value={this.state.flightNumberAlternative} onChange={this.onChangeUC} placeholder="Ersatzflugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative}
		    					/>
	    					</div>
	    					{this.state.errorCode === 1 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton><LinkButton className="cta1 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen" style={{ display: 'inline-block' }}>Weiter mit manueller Eingabe</LinkButton></div> : <button className={'cta1 anim1' + (this.state.checkFlightBtnDisabledAlternative ? ' disabled' : '')} disabled={this.state.checkFlightBtnDisabledAlternative} onClick={this.getFlightInfoAlternative}>{this.state.flightNumberAlternative === '' || this.state.flightDateAlternative === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'} <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>}
	    					{this.state.errorFlightInfoAlternative ? <p className="response">{this.state.errorFlightInfoAlternativeMessage}</p> : null}
    					</div>
    				</div>
    				{/* STEP 7 */}
    				<div id="step7" className={'anim2 step' + (this.state.currentStep === 7 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					{this.state.isReadyFlightInfoAlternative ? <FlightInfo flightInfo={this.state.flightInfoAlternative} flightNumber={this.state.flightNumberAlternative} /> : null }
    					<button className="cta1 anim1" onClick={() => this.jumpStep(8)}>Dies ist mein Flug</button>
    					<LinkButton className="cta2 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen">Mein Flug wird nicht angezeigt</LinkButton>
    				</div>
    				{/* STEP 8 */}
    				<div id="step8" className={'anim2 step' + (this.state.currentStep === 8 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={10} name="flightNumberAlternative2" value={this.state.flightNumberAlternative2} onChange={this.onChangeUC} placeholder="Ersatzflugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative2, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative2', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative2}
		    					/>
	    					</div>
	    					{this.state.errorCode === 1 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton><LinkButton className="cta1 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen" style={{ display: 'inline-block' }}>Weiter mit manueller Eingabe</LinkButton></div> : <button className={'cta1 anim1' + (this.state.checkFlightBtnDisabledAlternative2 ? ' disabled' : '')} disabled={this.state.checkFlightBtnDisabledAlternative2} onClick={this.getFlightInfoAlternative2}>{this.state.flightNumberAlternative2 === '' || this.state.flightDateAlternative2 === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'} <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>}
	    					{this.state.errorFlightInfoAlternative2 ? <p className="response">{this.state.errorFlightInfoAlternative2Message}</p> : null}
    					</div>
    				</div>
    				{/* STEP 9 */}
    				<div id="step9" className={'anim2 step' + (this.state.currentStep === 9 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					{this.state.isReadyFlightInfoAlternative2 ? <FlightInfo flightInfo={this.state.flightInfoAlternative2} flightNumber={this.state.flightNumberAlternative2} /> : null }
    					<button className="cta1 anim1" onClick={() => this.jumpStep(10)}>Dies ist mein Flug</button>
    					<LinkButton className="cta2 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen">Mein Flug wird nicht angezeigt</LinkButton>
    				</div>
    				{/* STEP 10 */}
    				<div id="step10" className={'anim2 step' + (this.state.currentStep === 10 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={10} name="flightNumberAlternative3" value={this.state.flightNumberAlternative3} onChange={this.onChangeUC} placeholder="Ersatzflugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative3, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative3', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative3}
		    					/>
	    					</div>
	    					{this.state.errorCode === 1 ? <div><LinkButton className="cta1 anim1" to="/" style={{ display: 'inline-block', marginRight: 20 }}>Anfrage beenden</LinkButton><LinkButton className="cta1 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen" style={{ display: 'inline-block' }}>Weiter mit manueller Eingabe</LinkButton></div> : <button className={'cta1 anim1' + (this.state.checkFlightBtnDisabledAlternative3 ? ' disabled' : '')} disabled={this.state.checkFlightBtnDisabledAlternative3} onClick={this.getFlightInfoAlternative3}>{this.state.flightNumberAlternative3 === '' || this.state.flightDateAlternative3 === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'} <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>}
	    					{this.state.errorFlightInfoAlternative3 ? <p className="response">{this.state.errorFlightInfoAlternative3Message}</p> : null}
    					</div>
    				</div>
    				{/* STEP 11 */}
    				<div id="step11" className={'anim2 step' + (this.state.currentStep === 11 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					{this.state.isReadyFlightInfoAlternative3 ? <FlightInfo flightInfo={this.state.flightInfoAlternative3} flightNumber={this.state.flightNumberAlternative3} /> : null }
    					<button className="cta1 anim1" onClick={() => this.jumpStep(12)}>Dies ist mein Flug</button>
    					<LinkButton className="cta2 anim1" to="/prozess/manuelle-eingabe-nicht-mitfliegen">Mein Flug wird nicht angezeigt</LinkButton>
    				</div>
    				{/* STEP 12 */}
    				<div id="step12" className={'anim2 step' + (this.state.currentStep === 12 ? ' visible' : '')}>
    					<h2>Haben Sie oder jemand anderes bereits selbst versucht von der Fluggesellschaft eine Entschädigung zu erhalten oder wurden Sie bereits entschädigt? *</h2>
						<h3 style={{ fontSize: 15 }}>* z.B. Gutschein oder anteilige Erstattung.</h3>
    					<div className="options" style={{ padding: '0 140px' }}>
	    					<button className={'anim1 option' + (this.state.contactedAirline === 'Ja' ? ' selected' : '')} onClick={() => this.selectContactedAirline('Ja')}><span className="anim1 fas fa-check"></span> Ja</button>
	    					<button className={'anim1 option' + (this.state.contactedAirline === 'Nein' ? ' selected' : '')} onClick={() => this.selectContactedAirline('Nein')}><span className="anim1 fas fa-times"></span> Nein</button>
    					</div>
    					<textarea className="anim1" name="contactedAirlineField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.contactedAirlineField}></textarea>
    					<div>
							<button className={'cta1 anim1' + (this.state.contactedAirline === '' ? ' disabled' : this.state.contactedAirline === 'Ja' && this.state.contactedAirlineField === '' ? ' disabled' : '')} disabled={(this.state.contactedAirline === '' ? true : this.state.contactedAirline === 'Ja' && this.state.contactedAirlineField === '' ? true : false)} onClick={() => this.jumpStep(13)}>Weiter</button>
						</div>
    				</div>
					{/* STEP 13 */}
    				<div id="step13" className={'anim2 step' + (this.state.currentStep === 13 ? ' visible' : '')}>
    					<h2>Sind Ihnen durch die Flugstörung zusätzliche Kosten entstanden? *</h2>
						<h3 style={{ fontSize: 15 }}>* z.B. Hotel-, Verpflegungs- oder Transferkosten.</h3>
    					<div className="options" style={{ padding: '0 140px' }}>
	    					<button className={'anim1 option' + (this.state.additionalCosts === 'Ja' ? ' selected' : '')} onClick={() => this.selectAdditionalCosts('Ja')}><span className="anim1 fas fa-check"></span> Ja</button>
	    					<button className={'anim1 option' + (this.state.additionalCosts === 'Nein' ? ' selected' : '')} onClick={() => this.selectAdditionalCosts('Nein')}><span className="anim1 fas fa-times"></span> Nein</button>
    					</div>
    					<textarea className="anim1" name="additionalCostsField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.additionalCostsField}></textarea>
    					<div>
							<button className={'cta1 anim1' + (this.state.additionalCosts === '' ? ' disabled' : this.state.additionalCosts === 'Ja' && this.state.additionalCostsField === '' ? ' disabled' : '')} disabled={(this.state.additionalCosts === '' ? true : this.state.additionalCosts === 'Ja' && this.state.additionalCostsField === '' ? true : false)} onClick={() => this.jumpStep(14)}>Weiter</button>
						</div>
    				</div>
    				{/* KONTAKT */}
    				{this.state.currentStep === 14 ? <KontaktProzess info={this.state} /> : null}
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default NichtMitfliegen;
