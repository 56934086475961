import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class Unterlagen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vorname: '',
			nachname: '',
			email: '',
			telefon: '',
			claimNumber: '',
			nachricht: '',
			files: {
				ticket: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				},
				bordkarten: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				},
				sonstiges1: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				},
				sonstiges2: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				},
				sonstiges3: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				},
				sonstiges4: {
					dataUri: '',
					fileName: '',
					fileType: '',
					fileExtension: '',
					file: ''
				}
			},
			hasFiles: false,
			datenschutz: false,
			emailError: true,
			disableSend: true,
			isLoading: false,
			isSent: false
		}
	}

	validateEmail = (event) => {
		var email = event.target.value;
		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(email).toLowerCase())) {
			this.refs.email.style.color = '#FF0000';
			this.setState({
				emailError: true
			}, function () {
				this.reviewData();
			});
		} else {
			this.refs.email.style.color = '#666666';
			this.setState({
				emailError: false
			}, function () {
				this.reviewData();
			});
		}
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		}, function () {
			this.reviewData();
		});
	}

	onCheckDatenschutz = () => {
		this.setState({
			datenschutz: !this.state.datenschutz
		}, function () {
			this.reviewData();
		});
	}


	onFileChange = (event) => {
		var eventName = event.target.name;
		var eventId = event.target.id;
		var reader = new FileReader();
		var file = event.target.files[0];
		var stateFiles = this.state.files;
		reader.onload = (upload) => {
			stateFiles[eventName]['dataUri'] = upload.target.result;
			stateFiles[eventName]['fileName'] = file.name;
			stateFiles[eventName]['fileType'] = file.type;
			stateFiles[eventName]['fileExtension'] = file.name.match(/\.[0-9a-z]+$/i);
			stateFiles[eventName]['file'] = event.target.files;
			if (stateFiles[eventName]['fileType'] !== 'image/jpeg' && stateFiles[eventName]['fileType'] !== 'image/png' && stateFiles[eventName]['fileType'] !== 'application/pdf') {
				alert("Nur JPG-, PNG- und PDF-Dateien werden akzeptiert.");
				document.getElementById(eventId).value = '';
				return false;
			} else {
				this.setState({
					files: stateFiles,
					hasFiles: true
				}, function () {
					this.reviewData();
				});
			}
		};
		reader.readAsDataURL(file);
	}

	reviewData = () => {
		var disableSend = true;
		if (this.state.emailError) {
			disableSend = true;
		} else if (!this.state.datenschutz) {
			disableSend = true;
		} else if (!this.state.hasFiles) {
			disableSend = true;
		} else {
			if (this.state.vorname === '' || this.state.nachname === '' || this.state.email === '' || this.state.claimNumber === '') {
				disableSend = true;
			} else {
				var claimNumber = this.state.claimNumber;
				var noUnderscore = claimNumber.replace(/_/g, '');
				if (noUnderscore.length < 11) {
					disableSend = true;
				} else {
					disableSend = false;
				}
			}
		}
		this.setState({
			disableSend: disableSend
		});
	}

	submitForm = (event) => {

		// Cancel default
		event.preventDefault();

		// Start
		this.setState({
			isLoading: true
		}, async function () {

			// Clean claim number
			var claimNumber = this.state.claimNumber.replaceAll('_', '');

			// Get folder ID
			const folders = await directus.items('directus_folders').readByQuery({
				fields: ['id'],
				filter: {
					name: {
						_eq: claimNumber
					}
				},
				limit: 1,
			});

			// Conditions
			if (folders.data.length === 0) {

				// Send error
				this.setState({
					isLoading: false,
					isSent: false
				}, function () {
					alert('Ihre Auftragsnummer wurde nicht gefunden. Versuchen Sie es bitte erneut.');
				});

			} else {

				// Get claim ID
				const claims = await directus.items('claims').readByQuery({
					fields: ['id'],
					filter: {
						claim_number: {
							_eq: claimNumber
						}
					},
					limit: 1,
				});

				// Set folder and claim ID
				const folderID = folders.data[0];
				const claimID = claims.data[0];

				// Upload files
				const ticket = this.state.files.ticket.file !== '' ? await this.uploadFile(folderID.id, 'ticket', this.state.files.ticket.file[0]) : '';
				const bordkarten = this.state.files.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten', this.state.files.bordkarten.file[0]) : '';
				const sonstiges1 = this.state.files.sonstiges1.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_1', this.state.files.sonstiges1.file[0]) : '';
				const sonstiges2 = this.state.files.sonstiges2.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_2', this.state.files.sonstiges2.file[0]) : '';
				const sonstiges3 = this.state.files.sonstiges3.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_3', this.state.files.sonstiges3.file[0]) : '';
				const sonstiges4 = this.state.files.sonstiges4.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_4', this.state.files.sonstiges4.file[0]) : '';

				// Insert into database
				await directus.items('unterlagen').createOne({
					claim: claimID.id,
					claim_number: this.state.claimNumber,
					vorname: this.state.vorname,
					nachname: this.state.nachname,
					email: this.state.email,
					telefon: this.state.telefon,
					nachricht: this.state.nachricht,
					files: {
						create: this.buildFiles([ticket, bordkarten, sonstiges1, sonstiges2, sonstiges3, sonstiges4]),
						update: [],
						delete: []
					},
				});

				// Remove loader and change page
				this.setState({
					isLoading: false,
					isSent: true
				}, function () {
					window.location.replace('/unterlagen/ende');
				});

			}

		});

	}

	// Build JSON for files uploaded
	buildFiles = (arr) => {

		// Loop files
		var finalArray = [];
		for (var i = 0; i < arr.length; i ++) {

			// Conditions
			if (arr[i] !== '') {

				// Add to array
				finalArray.push({
					claims_id: '+',
					directus_files_id: {
						id: arr[i],
					}
				});

			}

		}

		// Return array
		return finalArray;

	}

	// Upload a file and return the ID
	uploadFile = (folderID, name, file) => {

		// New promise
		return new Promise(async (resolve) => {

			// Create form data
			var formData = new FormData();

			// Append information
			formData.append('folder', folderID);
			formData.append(name, file);

			// Upload file
			await directus.files.createOne(formData).then((res) => {

				// Resolve file ID
				resolve(res.id);

			}).catch((err) => {

				// Log error
				console.log(err);

			});

		});

	}

	render() {
		return (
			<div>
				<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Unterlagen</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
				<Header section="Unterlagen" />
				<div id="process">
					<div className="anim1 step visible">
						<h2>Unterlagen</h2>
						<div className="tabber">
							<div className="tabberContent">
								<div>
									<input type="text" name="vorname" placeholder="Vorname *" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.vorname} />
									<input type="text" name="nachname" placeholder="Nachname *" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.nachname} />
									<input type="email" ref="email" name="email" placeholder="E-Mail *" className="third" onChange={this.onChange} value={this.state.email} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
									<input type="tel" name="telefon" placeholder="Telefon" className="third" onChange={this.onChange} value={this.state.telefon} />
									<MaskedInput mask="CL-2011.111111" name="claimNumber" placeholder="Auftragsnummer: CL-20 *" className="third" style={{ marginRight: 0 }} maxLength={24} onChange={this.onChange} value={this.state.claimNumber} />
									<textarea name="nachricht" placeholder="Nachricht" value={this.state.nachricht} onChange={this.onChange}></textarea>

									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="ticket1">{this.state.files.ticket.fileName === '' ? 'Buchungsbestätigung / Ticket' : this.state.files.ticket.fileName}</label>
										<input name="ticket" type="file" id="ticket1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="bordkarten1">{this.state.files.bordkarten.fileName === '' ? 'Bordkarten' : this.state.files.bordkarten.fileName}</label>
										<input name="bordkarten" type="file" id="bordkarten1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr" style={{ marginRight: 0 }}>
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges1">{this.state.files.sonstiges1.fileName === '' ? 'Sonstiges 1' : this.state.files.sonstiges1.fileName}</label>
										<input name="sonstiges1" type="file" id="sonstiges1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges2">{this.state.files.sonstiges2.fileName === '' ? 'Sonstiges 2' : this.state.files.sonstiges2.fileName}</label>
										<input name="sonstiges2" type="file" id="sonstiges2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges3">{this.state.files.sonstiges3.fileName === '' ? 'Sonstiges 3' : this.state.files.sonstiges3.fileName}</label>
										<input name="sonstiges3" type="file" id="sonstiges3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr" style={{ marginRight: 0 }}>
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges4">{this.state.files.sonstiges4.fileName === '' ? 'Sonstiges 4' : this.state.files.sonstiges4.fileName}</label>
										<input name="sonstiges4" type="file" id="sonstiges4" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>

								</div>
							</div>
						</div>
						<div className="datenschutzCheck">
							<input type="checkbox" name="datenschutz" value={this.state.datenschutz} onChange={this.onCheckDatenschutz} style={{ display: 'inline-block' }} />  Ich habe die <Link to="/datenschutz">Datenschutzerklärung</Link> gelesen und stimme der Weiterverarbeitung meiner Daten zur Bearbeitung meines Anliegens zu.
						</div>
						<button className={'cta1 anim1' + (this.state.disableSend || this.state.isLoading ? ' disabled' : '')} disabled={this.state.disableSend || this.state.isLoading ? true : false} onClick={this.submitForm}>Senden <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>
					</div>
				</div>
				<Footer className="withBorder" />
			</div>
		);
	}
}

export default Unterlagen;
