import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import { HashLink } from 'react-router-hash-link';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class BankInformationen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vorname: '',
			nachname: '',
			email: '',
			telefon: '',
			claimNumber: '',
			datenschutz: false,
			paymentCheck: false,
			emailError: true,
			disableSend: true,
			isLoading: false,
			isSent: false,
			bic: '',
			iban: '',
			paypalAccount: '',
			paymentOption: 'iban',
		}
	}

	validateEmail = (event) => {
    	var email = event.target.value;
    	if(email !== '') {
    		var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		    if(event.target.name === 'email') {
				if(!re.test(String(email).toLowerCase())) {
					this.refs.email.style.color = '#FF0000';
					this.setState({
						emailError: true
					}, function() {
						this.reviewData();
					});
				} else {
					this.refs.email.style.color = '#666666';
					this.setState({
						emailError: false
					}, function() {
						this.reviewData();
					});
				}
		    } else if(event.target.name === 'paypalAccount') {
		    	if(!re.test(String(email).toLowerCase())) {
		    		this.refs.paypalAccount.style.color = '#FF0000';
		    		this.setState({
		    			emailError: true
		    		}, function() {
		    			this.reviewData();
		    		});
		    	} else {
		    		this.refs.paypalAccount.style.color = '#666666';
		    		this.setState({
		    			emailError: false
		    		}, function() {
		    			this.reviewData();
		    		});
		    	}
		    }
    	} else {
    		if(event.target.name === 'email') {
    			this.refs.email.style.color = '#666666';
    		} else if(event.target.name === 'paypalAccount') {
    			this.refs.paypalAccount.style.color = '#666666';
    		}
    		this.setState({
    			emailError: false
    		}, function() {
    			this.reviewData();
    		});
    	}
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		}, function() {
        	this.reviewData();
        });
	}

	onCheckDatenschutz = () => {
		this.setState({
			datenschutz: !this.state.datenschutz
		}, function() {
        	this.reviewData();
        });
	}

	onCheckPayment = () => {
		this.setState({
			paymentCheck: !this.state.paymentCheck
		}, function() {
        	this.reviewData();
        });
	}

	reviewData = () => {
    	var disableSend = true;
    	if(this.state.emailError) {
    		disableSend = true;
    	} else if(!this.state.datenschutz) {
    		disableSend = true;
    	} else if(!this.state.paymentCheck) {
    		disableSend = true;
    	} else {
    		if(this.state.vorname === '' || this.state.nachname === '' || this.state.email === '' || this.state.claimNumber === '') {
	    		disableSend = true;
	    	} else {
	    		var claimNumber = this.state.claimNumber;
	    		var noUnderscore = claimNumber.replace(/_/g, '');
	    		if(noUnderscore.length < 11) {
	    			disableSend = true;
	    		} else {
	    			disableSend = false;
	    		}
	    	}
			if(this.state.paymentOption === 'iban') {
	    		if(this.state.bic === '' || this.state.iban === '') {
	    			disableSend = true;
	    		}
	    	} else if(this.state.paymentOption === 'paypal') {
	    		if(this.state.paypalAccount === '') {
	    			disableSend = true;
	    		}
	    	}
    	}
    	this.setState({
    		disableSend: disableSend
    	});
    }

	onRadioChange = (event) => {
		this.setState({
			paymentOption: event.target.value
		}, function() {
        	this.reviewData();
        });
	}

	submitForm = (event) => {

		// Cancel default
		event.preventDefault();
    	
		// Start
    	this.setState({
    		isLoading: true
    	}, async function() {

			// Clean claim number
			var claimNumber = this.state.claimNumber.replaceAll('_', '');

			// Get claim
			const claims = await directus.items('claims').readByQuery({
				limit: 1,
				fields: ['id'],
				filter: {
					claim_number: {
						_eq: claimNumber
					}
				},
			});

			// Conditions
			if (claims.data.length === 0) {

				// Send error
				this.setState({
					isLoading: false,
					isSent: false
				}, function() {
					alert('Ihre Auftragsnummer wurde nicht gefunden. Versuchen Sie es bitte erneut.');
				});

			} else {

				// Set claim ID
				const claimID = claims.data[0];

				// Insert into database
				await directus.items('bank_informationen').createOne({
					claim: claimID.id,
					claim_number: claimNumber,
					vorname: this.state.vorname,
					nachname: this.state.nachname,
					email: this.state.email,
					telefon: this.state.telefon,
					payment_type: this.state.paymentOption,
					iban: this.state.iban,
					bic: this.state.bic,
					paypal_address: this.state.paypalAccount,
				});

				// Remove loader and change page
				this.setState({
					isLoading: false,
					isSent: true
				}, function () {
					window.location.replace('/bank-informationen/ende');
				});

			}

    	});

    }

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Bank-Informationen</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
    			<Header section="Bank-Informationen" />
    			<div id="process">
    				<div className="anim1 step visible">
    					<h2>Bank-Informationen</h2>
	    				<div className="tabber">
							<div className="tabberContent">
								<div>
									<input type="text" name="vorname" placeholder="Vorname *" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.vorname} />
									<input type="text" name="nachname" placeholder="Nachname *" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.nachname} />
									<input type="email" ref="email" name="email" placeholder="E-Mail *" className="third" onChange={this.onChange} value={this.state.email} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
									<input type="tel" name="telefon" placeholder="Telefon" className="third" onChange={this.onChange} value={this.state.telefon} />
									<MaskedInput mask="CL-2011.111111" name="claimNumber" placeholder="Auftragsnummer: CL-20 *" className="third" style={{ marginRight: 0 }} maxLength={24} onChange={this.onChange} value={this.state.claimNumber} />
									<div className="inputRadioCtr">
										<input type="radio" value="iban" checked={this.state.paymentOption === 'iban'} onChange={this.onRadioChange} /> IBAN
									</div>
									<div className="inputRadioCtr" style={{ marginRight: 0 }}>
										<input type="radio" value="paypal" checked={this.state.paymentOption === 'paypal'} onChange={this.onRadioChange} /> PayPal
									</div>
									<span style={{ marginLeft: 35 }}>Auf dieses Konto zahlen wir die Entschädigung aus.</span>
									<div style={{ display: (this.state.paymentOption === 'iban' ? 'block' : 'none') }}>
										<MaskedInput mask="AA11 1111 1111 1111 1111 11" name="iban" placeholder="IBAN *" className="half" style={{ marginRight: '0.7%' }} maxLength={24} onChange={this.onChange} value={this.state.iban} />
										<MaskedInput mask="###########" name="bic" placeholder="BIC *" className="half" style={{ marginLeft: '0.7%' }} maxLength={15} onChange={this.onChange} value={this.state.bic} />
									</div>
									<div style={{ display: (this.state.paymentOption === 'paypal' ? 'block' : 'none') }}>
										<input type="email" ref="paypalAccount" name="paypalAccount" placeholder="PayPal-Konto *" onChange={this.onChange} value={this.state.paypalAccount} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
									</div>
								</div>
							</div>
						</div>
						<div className="paymentCheck">
							<input type="checkbox" name="paymentCheck" value={this.state.paymentCheck} onChange={this.onCheckPayment} style={{ display: 'inline-block' }} /> Ich habe meine <HashLink target="_blank" to="/agb#4-2" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Kontodaten</HashLink> für den Geldempfang nochmals überprüft und bestätige, dass diese so korrekt sind.
						</div>
						<div className="datenschutzCheck">
							<input type="checkbox" name="datenschutz" value={this.state.datenschutz} onChange={this.onCheckDatenschutz} style={{ display: 'inline-block' }} />  Ich habe die <Link to="/datenschutz">Datenschutzerklärung</Link> gelesen und stimme der Weiterverarbeitung meiner Daten zur Bearbeitung meines Anliegens zu.
						</div>
						<button className={'cta1 anim1' + (this.state.disableSend || this.state.isLoading ? ' disabled' : '')} disabled={this.state.disableSend || this.state.isLoading ? true : false} onClick={this.submitForm}>Senden <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>
					</div>
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default BankInformationen;
