import React, { Component } from 'react';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import LinkButton from '../elements/LinkButton';

class NotFound extends Component {
    render() {
    	return(
    		<div>
    			<Header section="Error 404" />
    			<div id="process">
    				<div className={'anim2 step visible'}>
    					<h2>Error 404 - Nicht gefunden</h2>
    					<LinkButton className="cta1 anim1" to="/">Neue Anfrage</LinkButton>
    				</div>
    			</div>
    			<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default NotFound;
