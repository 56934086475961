import React, { Component } from 'react';
import './App.css';
import AppRouter from './config/AppRouter';
import { BrowserRouter as Router, Route } from 'react-router-dom';

class App extends Component {
    render() {
        return(
            <Router>
            	<Route component={AppRouter} />
            </Router>
        );
    }
}

export default App;
