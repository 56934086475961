import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import { Link } from 'react-router-dom';
import BgLuggage1 from '../../assets/bg-luggage-1.jpg';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class Gepaeck extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vorname: '',
			nachname: '',
			nachricht: '',
			email: '',
			telefon: '',
			files: {
				pir: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				},
				bordkarten: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				},
				gepaeckabschnitt: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				},
				sonstiges1: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				},
				sonstiges2: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				},
				sonstiges3: {
					dataUri: '',
                    fileName: '',
                    fileType: '',
                    fileExtension: '',
					file: ''
				}
			},
			hasFiles: false,
			datenschutz: false,
			welchesProblem: '',
			emailError: true,
			disableSend: true,
			isLoading: false,
			isSent: false
		}
	}

	validateEmail = (event) => {
    	var email = event.target.value;
	    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    if(!re.test(String(email).toLowerCase())) {
	    	this.refs.email.style.color = '#FF0000';
	    	this.setState({
	    		emailError: true
	    	}, function() {
	    		this.reviewData();
	    	});
	    } else {
	    	this.refs.email.style.color = '#666666';
	    	this.setState({
	    		emailError: false
	    	}, function() {
	    		this.reviewData();
	    	});
	    }
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		}, function() {
        	this.reviewData();
        });
	}

	onCheckDatenschutz = () => {
		this.setState({
			datenschutz: !this.state.datenschutz
		}, function() {
        	this.reviewData();
        });
	}


    onFileChange = (event) => {
		var eventName = event.target.name;
		var eventId = event.target.id;
        var reader = new FileReader();
        var file = event.target.files[0];
        var stateFiles = this.state.files;
        reader.onload = (upload) => {
            stateFiles[eventName]['dataUri'] = upload.target.result;
            stateFiles[eventName]['fileName'] = file.name;
            stateFiles[eventName]['fileType'] = file.type;
            stateFiles[eventName]['fileExtension'] = file.name.match(/\.[0-9a-z]+$/i);
			stateFiles[eventName]['file'] = event.target.files;
            if(stateFiles[eventName]['fileType'] !== 'image/jpeg' && stateFiles[eventName]['fileType'] !== 'image/png' && stateFiles[eventName]['fileType'] !== 'application/pdf') {
                alert("Nur JPG-, PNG- und PDF-Dateien werden akzeptiert.");
                document.getElementById(eventId).value = '';
                return false;
            } else {
                this.setState({
                    files: stateFiles,
                    hasFiles: true
                }, function() {
                	this.reviewData();
                });
            }
        };
        reader.readAsDataURL(file);
    }

	reviewData = () => {
    	var disableSend = true;
    	if(this.state.emailError) {
    		disableSend = true;
    	} else if(!this.state.datenschutz) {
    		disableSend = true;
    	} else if(!this.state.hasFiles) {
    		disableSend = true;
    	} else {
    		if(this.state.vorname === '' || this.state.nachname === '' || this.state.email === '' || this.state.welchesProblem === '' || this.state.nachricht === '') {
	    		disableSend = true;
	    	} else {
	    		disableSend = false;
	    	}
    	}
    	this.setState({
    		disableSend: disableSend
    	});
    }

	submitForm = (event) => {

		// Cancel default
		event.preventDefault();
    	
		// Start
    	this.setState({
    		isLoading: true
    	}, async function() {

			// Variables
			var claimNumber = '';
			var fullYear = new Date().getFullYear();

			// Get latest claim numbers
			const cn = await directus.items('claims').readByQuery({
				limit: 1,
				sort: ['-date_created'],
				fields: ['claim_number']
			});
			const gn = await directus.items('gepaeck').readByQuery({
				limit: 1,
				sort: ['-date_created'],
				fields: ['claim_number']
			});

			// Combine arrays
			const combinedData = cn.data.concat(gn.data);

			// Conditions
			if (combinedData.length === 0) {

				// Start new
				claimNumber = 'CL-' + fullYear + '.110';

			} else {

				// Find the latest claim number from combined data
				const latestClaimNumber = combinedData.reduce((latest, item) => {
					const [prefix, yearNumber] = item.claim_number.split('-'); // e.g., 'CL-2023.110'
					const [year, number] = yearNumber.split('.').map(Number); // e.g., [2023, 110]
					const currentLatest = latest.split('-')[1].split('.').map(Number); // e.g., [2023, 110]
					if (year > currentLatest[0] || (year === currentLatest[0] && number > currentLatest[1])) {
						return item.claim_number;
					}
					return latest;
				}, 'CL-0.0'); // Initial value to compare with
			
				// Split the latest claim number
				var [_, latestYearNumber] = latestClaimNumber.split('-'); // '2023.110'
				var [year, number] = latestYearNumber.split('.').map(Number); // [2023, 110]
			
				// Determine the next claim number
				if (year === fullYear) {
					// Random number between 2 and 99 for new claim number
					var random = Math.floor(Math.random() * (99 - 2 + 1)) + 2;
					// If the latest claim number's year matches the current year, increment the number
					claimNumber = `CL-${fullYear}.${number + random}`;
				} else {
					// If the latest claim number's year does not match, start a new series for the current year
					claimNumber = 'CL-' + fullYear + '.110';
				}

			}

			// Create folder
			const folderID = await directus.items('directus_folders').createOne({
				parent: 'c6f845a5-e3e1-40af-8a88-1e9029f77fb1',
				name: claimNumber,
			});

			// Upload files
			const pir = this.state.files.pir.file !== '' ? await this.uploadFile(folderID.id, 'pir', this.state.files.pir.file[0]) : '';
			const bordkarten = this.state.files.bordkarten.file !== '' ? await this.uploadFile(folderID.id, 'bordkarten', this.state.files.bordkarten.file[0]) : '';
			const gepaeckabschnitt = this.state.files.gepaeckabschnitt.file !== '' ? await this.uploadFile(folderID.id, 'gepaeckabschnitt', this.state.files.gepaeckabschnitt.file[0]) : '';
			const sonstiges1 = this.state.files.sonstiges1.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_1', this.state.files.sonstiges1.file[0]) : '';
			const sonstiges2 = this.state.files.sonstiges2.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_2', this.state.files.sonstiges2.file[0]) : '';
			const sonstiges3 = this.state.files.sonstiges3.file !== '' ? await this.uploadFile(folderID.id, 'sonstiges_3', this.state.files.sonstiges3.file[0]) : '';

			// Insert into database
			await directus.items('gepaeck').createOne({
				claim_number: claimNumber,
				vorname: this.state.vorname,
				nachname: this.state.nachname,
				email: this.state.email,
				telefon: this.state.telefon,
				problem: this.state.welchesProblem,
				nachricht: this.state.nachricht,
				files: {
					create: this.buildFiles([pir, bordkarten, gepaeckabschnitt, sonstiges1, sonstiges2, sonstiges3]),
					update: [],
					delete: []
				},
			});

    		// Remove loader and change page
			this.setState({
				isLoading: false,
				isSent: true,
				claimNumber: claimNumber
			}, function () {
				window.location.replace('/prozess/ende');
			});

    	});

    }

	// Build JSON for files uploaded
	buildFiles = (arr) => {

		// Loop files
		var finalArray = [];
		for (var i = 0; i < arr.length; i ++) {

			// Conditions
			if (arr[i] !== '') {

				// Add to array
				finalArray.push({
					claims_id: '+',
					directus_files_id: {
						id: arr[i],
					}
				});

			}

		}

		// Return array
		return finalArray;

	}

	// Upload a file and return the ID
	uploadFile = (folderID, name, file) => {

		// New promise
		return new Promise(async (resolve) => {

			// Create form data
			var formData = new FormData();

			// Append information
			formData.append('folder', folderID);
			formData.append(name, file);

			// Upload file
			await directus.files.createOne(formData).then((res) => {

				// Resolve file ID
				resolve(res.id);

			}).catch((err) => {

				// Log error
				console.log(err);

			});

		});

	}

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Gepäck</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
    			<Header section="Gepäck" />
    			<div id="process" style={{ backgroundImage: 'url(' + BgLuggage1 + ')' }}>
    				<div className="anim1 step visible">
    					<h2>Welches Problem hatten Sie mit Ihrem Gepäck?</h2>
	    				<div className="tabber">
							<div className="tabberContent">
								<div>
									<input type="text" name="vorname" placeholder="Vorname *" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.vorname} />
									<input type="text" name="nachname" placeholder="Nachname *" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.nachname} />
									<input type="email" ref="email" name="email" placeholder="E-Mail *" className="third" onChange={this.onChange} value={this.state.email} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
									<input type="tel" name="telefon" placeholder="Telefon" className="third" onChange={this.onChange} value={this.state.telefon} />
									<select name="welchesProblem" value={this.state.welchesProblem} className="third" style={{ marginRight: 0 }} onChange={this.onChange}>
										<option value="">Welches Problem? *</option>
										<option value="beschaedigt">Mein Gepäck wurde beschädigt</option>
										<option value="verspaetet">Mein Gepäck kam verspätet an</option>
										<option value="vermisst">Mein Gepäck wird bis heute vermisst</option>
									</select>
									<textarea name="nachricht" placeholder="Nachricht *" value={this.state.nachricht} onChange={this.onChange}></textarea>

									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="pir1">{this.state.files.pir.fileName === '' ? 'PIR' : this.state.files.pir.fileName}</label>
										<input name="pir" type="file" id="pir1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="bordkarten1">{this.state.files.bordkarten.fileName === '' ? 'Bordkarten' : this.state.files.bordkarten.fileName}</label>
										<input name="bordkarten" type="file" id="bordkarten1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr" style={{ marginRight: 0 }}>
										<span className="fas fa-paperclip"></span>
										<label htmlFor="gepaeckabschnitt1">{this.state.files.gepaeckabschnitt.fileName === '' ? 'Sonstiges 1' : this.state.files.gepaeckabschnitt.fileName}</label>
										<input name="gepaeckabschnitt" type="file" id="gepaeckabschnitt1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges1">{this.state.files.sonstiges1.fileName === '' ? 'Sonstiges 2' : this.state.files.sonstiges1.fileName}</label>
										<input name="sonstiges1" type="file" id="sonstiges1" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr">
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges2">{this.state.files.sonstiges2.fileName === '' ? 'Sonstiges 3' : this.state.files.sonstiges2.fileName}</label>
										<input name="sonstiges2" type="file" id="sonstiges2" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>
									<div className="inputFileCtr" style={{ marginRight: 0 }}>
										<span className="fas fa-paperclip"></span>
										<label htmlFor="sonstiges3">{this.state.files.sonstiges3.fileName === '' ? 'Sonstiges 4' : this.state.files.sonstiges3.fileName}</label>
										<input name="sonstiges3" type="file" id="sonstiges3" style={{ display: 'none' }} accept=".jpg, .png, .jpeg, .pdf" onChange={this.onFileChange} />
									</div>

								</div>
							</div>
						</div>
						<div className="datenschutzCheck">
							<input type="checkbox" name="datenschutz" value={this.state.datenschutz} onChange={this.onCheckDatenschutz} style={{ display: 'inline-block' }} />  Ich habe die <Link to="/datenschutz">Datenschutzerklärung</Link> gelesen und stimme der Weiterverarbeitung meiner Daten zur Bearbeitung meines Anliegens zu.
						</div>
						<button className={'cta1 anim1' + (this.state.disableSend || this.state.isLoading ? ' disabled' : '')} disabled={this.state.disableSend || this.state.isLoading ? true : false} onClick={this.submitForm}>Senden <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>
						<div className="formAdditionalText">
							<p>
							Bitte beachten Sie, dass Sie das Gepäckproblem umgehend nach Ankunft beim Lost & Found Schalter ihrer Fluggesellschaft anzeigen müssen. Sollte der Lost &amp; Found Schalter nicht besetzt sein, kann die sogenannte PIR Meldung (Property Irregularity Report) bei den meisten Fluggesellschaften auch online aufgegeben werden. Die Meldefrist bei Gepäckverspätungen beträgt maximal 21 Tage, bei Gepäckbeschädigungen sind es sogar nur 7 Tage.<br /><br />
							<strong>Zur Bearbeitung Ihres Anliegens benötigen wir zwingend den PIR!</strong>
							</p>
						</div>
					</div>
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default Gepaeck;
