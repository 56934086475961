import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import BgImage from '../../assets/bg-datenschutz.jpg';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

class Impressum extends Component {
	constructor(props) {
		super(props);
		this.state = {
			impressumContent: '',
		}
	}

	async componentDidMount() {

		// Connect to Directus
		const directus = new Directus(apiUrl);

		// Get list
		const impressumContent  = await directus.items('page_impressum').readByQuery({ fields: ['*'] });

		// Assign
		this.setState({
			impressumContent: impressumContent.data.content,
		});

	}

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Impressum</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
    			<Header section="Impressum" />
    			<div id="fullContainer" style={{ backgroundImage: 'url(' + BgImage + ')' }}>
					<div className="text" dangerouslySetInnerHTML={{ __html: this.state.impressumContent }}></div>
				</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default Impressum;
