import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import { Link } from 'react-router-dom';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class Kontakt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vorname: '',
			nachname: '',
			email: '',
			telefon: '',
			nachricht: '',
			datenschutz: false,
			emailError: true,
			disableSend: true,
			isLoading: false,
			isSent: false
		}
	}

	validateEmail = (event) => {
    	var email = event.target.value;
	    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    if(!re.test(String(email).toLowerCase())) {
	    	this.refs.email.style.color = '#FF0000';
	    	this.setState({
	    		emailError: true
	    	}, function() {
	    		this.reviewData();
	    	});
	    } else {
	    	this.refs.email.style.color = '#666666';
	    	this.setState({
	    		emailError: false
	    	}, function() {
	    		this.reviewData();
	    	});
	    }
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		}, function() {
        	this.reviewData();
        });
	}

	onCheckDatenschutz = () => {
		this.setState({
			datenschutz: !this.state.datenschutz
		}, function() {
        	this.reviewData();
        });
	}

	reviewData = () => {
    	var disableSend = true;
    	if(this.state.emailError) {
    		disableSend = true;
    	} else if(!this.state.datenschutz) {
    		disableSend = true;
    	} else {
    		if(this.state.vorname === '' || this.state.nachname === '' || this.state.email === '' || this.state.nachricht === '') {
	    		disableSend = true;
	    	} else {
	    		disableSend = false;
	    	}
    	}
    	this.setState({
    		disableSend: disableSend
    	});
    }

	submitForm = () => {
    	var self = this;
    	this.setState({
    		isLoading: true
    	}, function() {
			directus.items('contact').createOne({
				vorname: this.state.vorname,
				nachname: this.state.nachname,
				email: this.state.email,
				telefon: this.state.telefon,
				nachricht: this.state.nachricht
			}).then(() => {
				self.setState({
					isLoading: false,
					isSent: true
				}, function() {
					window.location.replace('/ende');
				});
			}).catch((error) => {
				console.log(error);
			});
    	});
    }

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Kontakt</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
    			<Header section="Kontakt" />
    			<div id="process">
    				<div className="anim1 step visible">
    					<h2>Kontakt</h2>
						<h3>Haben Sie Fragen? Dann schreiben Sie uns!</h3>
	    				<div className="tabber">
							<div className="tabberContent">
								<div>
									<input type="text" name="vorname" placeholder="Vorname *" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.vorname} />
									<input type="text" name="nachname" placeholder="Nachname *" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.nachname} />
									<input type="email" ref="email" name="email" placeholder="E-Mail *" className="half" style={{ marginRight: '0.7%' }} onChange={this.onChange} value={this.state.email} onKeyUp={this.validateEmail} onBlur={this.validateEmail} onFocus={this.validateEmail} />
									<input type="tel" name="telefon" placeholder="Telefon" className="half" style={{ marginLeft: '0.7%' }} onChange={this.onChange} value={this.state.telefon} />
									<textarea name="nachricht" placeholder="Nachricht *" value={this.state.nachricht} onChange={this.onChange}></textarea>
								</div>
							</div>
						</div>
						<div className="datenschutzCheck">
							<input type="checkbox" name="datenschutz" value={this.state.datenschutz} onChange={this.onCheckDatenschutz} style={{ display: 'inline-block' }} />  Ich habe die <a href="/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a> gelesen und stimme der Weiterverarbeitung meiner Daten zur Bearbeitung meines Anliegens zu.
						</div>
						<button className={'cta1 anim1' + (this.state.disableSend || this.state.isLoading ? ' disabled' : '')} disabled={this.state.disableSend || this.state.isLoading ? true : false} onClick={this.submitForm}>Senden <span className="fas fa-spinner fa-spin" style={{ display: (this.state.isLoading ? 'inline-block' : 'none') }}></span></button>
						<div className="info" style={{ marginTop: 35 }}>
							<h3>FLUG. CLAIM</h3>
							<p>
								Postfach 1218<br />
								D-63232 Neu-Isenburg
							</p>
							<p>
								Fax: +49 (0) 6102 3527461<br />
								E-Mail: <a href="mailto:info@flug-claim.de">info@flug-claim.de</a>
							</p>
							<p style={{ fontSize: 12, lineHeight: '18px' }}>
								Bitte beachten Sie, dass wir momentan keinen telefonischen Kundendienst anbieten!
							</p>
						</div>
					</div>
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default Kontakt;
