import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import DateTime from 'react-datetime';
import '../../../DateTime.css';
import Header from '../../structure/Header';
import Footer from '../../structure/Footer';
import LinkButton from '../../elements/LinkButton';
import KontaktProzess from '../../views/KontaktProzess';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../../constants/server';

require('moment/locale/de');

// Connect to Directus
const directus = new Directus(apiUrl);

class ManuelleEingabeAnnullierung extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep: 1,
			problemOption: 'Mein Flug wurde annulliert',
			inputType: 'manual',
			flightCarrier: '',
			flightIataCode: '',
			flightNumber: '',
			flightDate: '',
			flightCarrierConnection: '',
			flightIataCodeConnection: '',
			flightNumberConnection: '',
			flightDateConnection: '',
			flightCarrierAlternative: '',
			flightIataCodeAlternative: '',
			flightNumberAlternative: '',
			flightDateAlternative: '',
			flightCarrierAlternative2: '',
			flightIataCodeAlternative2: '',
			flightNumberAlternative2: '',
			flightDateAlternative2: '',
			flightCarrierAlternative3: '',
			flightIataCodeAlternative3: '',
			flightNumberAlternative3: '',
			flightDateAlternative3: '',
			airlines: [],
			selectedAirline: '',
			selectedAirlineConnection: '',
			selectedAirlineAlternative: '',
			selectedAirlineAlternative2: '',
			selectedAirlineAlternative3: '',
			delayInformationField: '',
			delayReason: '',
			isValidPastDate: function(){},
			isValidOtherDate: function(){},
			isValidPastNoticeDate: function(){},
			closeLightbox: false,
			flightCancelInfo: '',
			contactedAirline: '',
			contactedAirlineField: '',
			additionalCosts: '',
			additionalCostsField: '',
		}
	}

	async componentDidMount() {
		var validDate = function(current) {
			return current.isBefore(moment()) && current.isAfter(moment().subtract(3, 'year'));
		};
		this.setState({
			isValidPastDate: validDate
		});
		validDate = function(current) {
			return current.isBefore(moment().add(2, 'day'));
		};
		this.setState({
			isValidOtherDate: validDate
		});
		const airlines = await directus.items('all_airlines').readByQuery({
			fields: ['name_airline', 'code_iata_airline'],
			filter: {
				deny_on_process: {
					_eq: false
				},
				status_airline: {
					_eq: 'active'
				},
				name_airline: {
					_nempty: true
				},
				code_iata_airline: {
					_nempty: true
				},
			},
			limit: 2000,
		});
		this.setState({
			airlines: airlines.data,
		});
	}

	jumpStep = (step) => {
		this.setState({
			currentStep: step
		});
	}

	selectContactedAirline = (option) => {
		this.setState({
			contactedAirline: option
		});
	}

	selectAdditionalCosts = (option) => {
		this.setState({
			additionalCosts: option
		});
	}

	onChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	}

	onChangeAirline = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectedAirline: ''
		});
	}

	onChangeAirlineConnection = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectedAirlineConnection: ''
		});
	}

	onChangeAirlineAlternative = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectedAirlineAlternative: ''
		});
	}

	onChangeAirlineAlternative2 = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectedAirlineAlternative2: ''
		});
	}

	onChangeAirlineAlternative3 = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
			selectedAirlineAlternative3: ''
		});
	}

	onChangeNaN = (event) => {
		if(isNaN(event.target.value)) {
			return false;
		} else {
			this.setState({
				[event.target.name]: event.target.value
			});
		}
	}

	onChangeDate = (date) => {
		var self = this;
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDate: format,
			flightCancelInfo: format
		}, function() {
			var validDate = function(current) {
				return current.isBefore(moment(self.state.flightDate, "DD.MM.YYYY").add(4, 'day'));
			};
			this.setState({
				isValidPastNoticeDate: validDate
			});
		});
	}

	onChangeDateCancel = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightCancelInfo: format
		});
	}

	onChangeDateConnection = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateConnection: format
		});
	}

	onChangeDateAlternative = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative: format
		});
	}

	onChangeDateAlternative2 = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative2: format
		});
	}

	onChangeDateAlternative3 = (date) => {
		var format = moment(date._d).format('DD.MM.YYYY');
		this.setState({
			flightDateAlternative3: format
		});
	}

	selectDelayReason = (reason) => {
		if(reason === 'Kein Grund genannt' || reason === 'Streik' || reason === 'Wetter') {
			if(reason === 'Streik' || reason === 'Wetter') {
				this.setState({
					closeLightbox: false
				});
			}
			this.setState({
				delayInformationField: ''
			});
		}
		this.setState({
			delayReason: reason
		});
	}

	eachAirline = ([key, val], i) => {
		return (
			<div className="airline anim1" onClick={() => this.selectAirline(val.code_iata_airline, val.name_airline)} key={i}>{val.code_iata_airline}: {val.name_airline}</div>
		);
	}

	eachAirlineConnection = ([key, val], i) => {
		return (
			<div className="airline anim1" onClick={() => this.selectAirlineConnection(val.code_iata_airline, val.name_airline)} key={i}>{val.code_iata_airline}: {val.name_airline}</div>
		);
	}

	eachAirlineAlternative = ([key, val], i) => {
		return (
			<div className="airline anim1" onClick={() => this.selectAirlineAlternative(val.code_iata_airline, val.name_airline)} key={i}>{val.code_iata_airline}: {val.name_airline}</div>
		);
	}

	eachAirlineAlternative2 = ([key, val], i) => {
		return (
			<div className="airline anim1" onClick={() => this.selectAirlineAlternative2(val.code_iata_airline, val.name_airline)} key={i}>{val.code_iata_airline}: {val.name_airline}</div>
		);
	}

	eachAirlineAlternative3 = ([key, val], i) => {
		return (
			<div className="airline anim1" onClick={() => this.selectAirlineAlternative3(val.code_iata_airline, val.name_airline)} key={i}>{val.code_iata_airline}: {val.name_airline}</div>
		);
	}

	selectAirline = (iata, name) => {
		this.setState({
			selectedAirline: name
		}, function() {
			this.setState({
				flightCarrier: name,
				flightIataCode: iata
			}, function() {
				this.refs.flightNumber.focus();
			});
		});
	}

	selectAirlineConnection = (iata, name) => {
		this.setState({
			selectedAirlineConnection: name
		}, function() {
			this.setState({
				flightCarrierConnection: name,
				flightIataCodeConnection: iata
			}, function() {
				this.refs.flightNumberConnection.focus();
			});
		});
	}

	selectAirlineAlternative = (iata, name) => {
		this.setState({
			selectedAirlineAlternative: name
		}, function() {
			this.setState({
				flightCarrierAlternative: name,
				flightIataCodeAlternative: iata
			}, function() {
				this.refs.flightNumberAlternative.focus();
			});
		});
	}

	selectAirlineAlternative2 = (iata, name) => {
		this.setState({
			selectedAirlineAlternative2: name
		}, function() {
			this.setState({
				flightCarrierAlternative2: name,
				flightIataCodeAlternative2: iata
			}, function() {
				this.refs.flightNumberAlternative2.focus();
			});
		});
	}

	selectAirlineAlternative3 = (iata, name) => {
		this.setState({
			selectedAirlineAlternative3: name
		}, function() {
			this.setState({
				flightCarrierAlternative3: name,
				flightIataCodeAlternative3: iata
			}, function() {
				this.refs.flightNumberAlternative3.focus();
			});
		});
	}

	closeLightbox = () => {
		this.setState({
			closeLightbox: true
		});
	}

	processBack = () => {
		// Check current step
		if(this.state.currentStep === 1) {
			// Go to home
			this.props.history.replace('/');
		} else {
			// Navigate a step before
			this.setState({
				currentStep: this.state.currentStep - 1
			});
		}
	}

    render() {
    	let airlines = this.state.airlines.filter(
            (airline) => {
                return airline.name_airline.toLowerCase().indexOf(this.state.flightCarrier.toLowerCase()) !== -1;
            }
        );
        let airlinesConnection = this.state.airlines.filter(
            (airline) => {
                return airline.name_airline.toLowerCase().indexOf(this.state.flightCarrierConnection.toLowerCase()) !== -1;
            }
        );
        let airlinesAlternative = this.state.airlines.filter(
            (airline) => {
                return airline.name_airline.toLowerCase().indexOf(this.state.flightCarrierAlternative.toLowerCase()) !== -1;
            }
        );
        let airlinesAlternative2 = this.state.airlines.filter(
            (airline) => {
                return airline.name_airline.toLowerCase().indexOf(this.state.flightCarrierAlternative2.toLowerCase()) !== -1;
            }
        );
        let airlinesAlternative3 = this.state.airlines.filter(
            (airline) => {
                return airline.name_airline.toLowerCase().indexOf(this.state.flightCarrierAlternative3.toLowerCase()) !== -1;
            }
        );
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Flug Annulliert</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
				{/* Back button */}
				<button
					id="backButton"
					className="anim1"
					onClick={this.processBack}
				>
					<span className="fas fa-chevron-left"></span> Zurück
				</button>
    			{this.state.delayReason === 'Wetter' ? <div className="lightbox" style={{ display: (this.state.closeLightbox ? 'none' : 'flex') }}><span className="fas fa-times close" onClick={this.closeLightbox}></span><p><span style={{ color: '#C0392B', fontWeight: 700 }}>Dieser Flug hat leider keinen Anspruch auf Entschädigung, da „außergewöhnliche Umstände“ der Anlass für die Annullierung / Flugverspätung waren.</span><br /><br />Außergewöhnliche Umstände sind laut EU-Verordnung Ereignisse, die zur Verspätung oder Annullierung eines Fluges geführt haben, aber nicht im Verantwortungsbereich der Fluggesellschaft liegen. Hierzu zählen beispielsweise ein Streik, Flugraumsperrungen, schlechte Wetterbedingungen oder politische Instabilität im Land. Die Airline ist in diesen Fällen nicht verpflichtet, eine Entschädigung zu zahlen.</p></div> : null}
    			<Header section="Annullierung" />
    			<div id="process">
    				{/* STEP 1 */}
    				<div id="step1" className={'anim2 step' + (this.state.currentStep === 1 ? ' visible' : '')}>
    					<h2>Wie lautet Ihre Fluggesellschaft, Ihre Flugnummer und Ihr Flugdatum?</h2>
    					<div className="form">
    						<div className="inputCtr">
	    						<span className="fas fa-building"></span>
	    						<input type="text" className="anim1" name="flightCarrier" value={this.state.flightCarrier} onChange={this.onChangeAirline} placeholder="Fluggesellschaft" />
	    						<div className="airlinesList" style={{ display: (this.state.flightCarrier === '' ? 'none' : this.state.selectedAirline !== '' ? 'none' : 'block') }}>
	    							{Object.keys(this.state.airlines).length !== 0 ? Object.entries(airlines).map(this.eachAirline) : null}
	    						</div>
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={5} ref="flightNumber" name="flightNumber" value={this.state.flightNumber} onChange={this.onChangeNaN} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDate, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidPastDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Flugdatum', className: 'anim1', name: 'flightDate', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDate}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1' + (this.state.flightNumber === '' || this.state.flightDate === '' || this.state.selectedAirline === '' ? ' disabled' : '')} disabled={this.state.flightNumber === '' || this.state.flightDate === '' || this.state.selectedAirline === '' ? true : false} onClick={() => this.jumpStep(2)}>Weiter</button>
    					</div>
    				</div>
    				{/* STEP 2 */}
    				<div id="step2" className={'anim2 step' + (this.state.currentStep === 2 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					<div className="flightInfo">
							<p className="airlineFlight" style={{ paddingBottom: 0 }}>{this.state.flightCarrier + ' - ' + this.state.flightIataCode + this.state.flightNumber}</p>
						</div>
    					<button className="cta1 anim1" onClick={() => this.jumpStep(3)}>Weiter</button>
    				</div>
					{/* STEP 3 */}
    				<div id="step3" className={'anim2 step' + (this.state.currentStep === 3 ? ' visible' : '')}>
    					<h2>Wann wurden Sie über die Annullierung informiert?</h2>
    					<div className="form">
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
		    						value={moment(this.state.flightCancelInfo, "DD.MM.YYYY")}
	    							dateFormat={'DD.MM.YYYY'}
	    							isValidDate={this.state.isValidPastNoticeDate}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Datum', className: 'anim1', name: 'flightCancelInfo', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateCancel}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1' + (this.state.flightCancelInfo === '' ? ' disabled' : '')} disabled={this.state.flightCancelInfo === '' ? true : false} onClick={() => this.jumpStep(4)}>Weiter</button>
    					</div>
    				</div>
    				{/* STEP 4 */}
    				<div id="step4" className={'anim2 step' + (this.state.currentStep === 4 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Anschlussflug?</h2>
    					<div className="form">
							<div className="inputCtr">
	    						<span className="fas fa-building"></span>
	    						<input type="text" className="anim1" name="flightCarrierConnection" value={this.state.flightCarrierConnection} onChange={this.onChangeAirlineConnection} placeholder="Fluggesellschaft" />
	    						<div className="airlinesList" style={{ display: (this.state.flightCarrierConnection === '' ? 'none' : this.state.selectedAirlineConnection !== '' ? 'none' : 'block') }}>
	    							{Object.keys(this.state.airlines).length !== 0 ? Object.entries(airlinesConnection).map(this.eachAirlineConnection) : null}
	    						</div>
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={5} ref="flightNumberConnection" name="flightNumberConnection" value={this.state.flightNumberConnection} onChange={this.onChangeNaN} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateConnection, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Anschlussflugdatum', className: 'anim1', name: 'flightDateConnection', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateConnection}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1'} onClick={() => this.jumpStep(this.state.flightNumberConnection === '' || this.state.flightDateConnection === '' || this.state.selectedAirlineConnection === '' ? 6 : 5)}>{this.state.flightNumberConnection === '' || this.state.flightDateConnection === '' || this.state.selectedAirlineConnection === '' ? 'Weiter ohne Anschlussflug' : 'Weiter'}</button>
    					</div>
    				</div>
    				{/* STEP 5 */}
    				<div id="step5" className={'anim2 step' + (this.state.currentStep === 5 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					<div className="flightInfo">
							<p className="airlineFlight" style={{ paddingBottom: 0 }}>{this.state.flightCarrierConnection + ' - ' + this.state.flightIataCodeConnection + this.state.flightNumberConnection}</p>
						</div>
    					<button className="cta1 anim1" onClick={() => this.jumpStep(6)}>Weiter</button>
    				</div>
					{/* STEP 6 */}
    				<div id="step6" className={'anim2 step' + (this.state.currentStep === 6 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
							<div className="inputCtr">
	    						<span className="fas fa-building"></span>
	    						<input type="text" className="anim1" name="flightCarrierAlternative" value={this.state.flightCarrierAlternative} onChange={this.onChangeAirlineAlternative} placeholder="Fluggesellschaft" />
	    						<div className="airlinesList" style={{ display: (this.state.flightCarrierAlternative === '' ? 'none' : this.state.selectedAirlineAlternative !== '' ? 'none' : 'block') }}>
	    							{Object.keys(this.state.airlines).length !== 0 ? Object.entries(airlinesAlternative).map(this.eachAirlineAlternative) : null}
	    						</div>
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={5} ref="flightNumberAlternative" name="flightNumberAlternative" value={this.state.flightNumberAlternative} onChange={this.onChangeNaN} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1'} onClick={() => this.jumpStep(this.state.flightNumberAlternative === '' || this.state.flightDateAlternative === '' || this.state.selectedAirlineAlternative === '' ? 12 : 7)}>{this.state.flightNumberAlternative === '' || this.state.flightDateAlternative === '' || this.state.selectedAirlineAlternative === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'}</button>
    					</div>
    				</div>
    				{/* STEP 7 */}
    				<div id="step7" className={'anim2 step' + (this.state.currentStep === 7 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					<div className="flightInfo">
							<p className="airlineFlight" style={{ paddingBottom: 0 }}>{this.state.flightCarrierAlternative + ' - ' + this.state.flightIataCodeAlternative + this.state.flightNumberAlternative}</p>
						</div>
    					<button className="cta1 anim1" onClick={() => this.jumpStep(8)}>Weiter</button>
    				</div>
					{/* STEP 8 */}
    				<div id="step8" className={'anim2 step' + (this.state.currentStep === 8 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
							<div className="inputCtr">
	    						<span className="fas fa-building"></span>
	    						<input type="text" className="anim1" name="flightCarrierAlternative2" value={this.state.flightCarrierAlternative2} onChange={this.onChangeAirlineAlternative2} placeholder="Fluggesellschaft" />
	    						<div className="airlinesList" style={{ display: (this.state.flightCarrierAlternative2 === '' ? 'none' : this.state.selectedAirlineAlternative2 !== '' ? 'none' : 'block') }}>
	    							{Object.keys(this.state.airlines).length !== 0 ? Object.entries(airlinesAlternative2).map(this.eachAirlineAlternative2) : null}
	    						</div>
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={5} ref="flightNumberAlternative2" name="flightNumberAlternative2" value={this.state.flightNumberAlternative2} onChange={this.onChangeNaN} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative2, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative2', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative2}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1'} onClick={() => this.jumpStep(this.state.flightNumberAlternative2 === '' || this.state.flightDateAlternative2 === '' || this.state.selectedAirlineAlternative2 === '' ? 12 : 9)}>{this.state.flightNumberAlternative2 === '' || this.state.flightDateAlternative2 === '' || this.state.selectedAirlineAlternative2 === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'}</button>
    					</div>
    				</div>
    				{/* STEP 9 */}
    				<div id="step9" className={'anim2 step' + (this.state.currentStep === 9 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					<div className="flightInfo">
							<p className="airlineFlight" style={{ paddingBottom: 0 }}>{this.state.flightCarrierAlternative2 + ' - ' + this.state.flightIataCodeAlternative2 + this.state.flightNumberAlternative2}</p>
						</div>
    					<button className="cta1 anim1" onClick={() => this.jumpStep(10)}>Weiter</button>
    				</div>
    				{/* STEP 10 */}
    				<div id="step10" className={'anim2 step' + (this.state.currentStep === 10 ? ' visible' : '')}>
    					<h2>Hatten Sie noch einen Ersatzflug?</h2>
    					<div className="form">
							<div className="inputCtr">
	    						<span className="fas fa-building"></span>
	    						<input type="text" className="anim1" name="flightCarrierAlternative3" value={this.state.flightCarrierAlternative3} onChange={this.onChangeAirlineAlternative3} placeholder="Fluggesellschaft" />
	    						<div className="airlinesList" style={{ display: (this.state.flightCarrierAlternative3 === '' ? 'none' : this.state.selectedAirlineAlternative3 !== '' ? 'none' : 'block') }}>
	    							{Object.keys(this.state.airlines).length !== 0 ? Object.entries(airlinesAlternative3).map(this.eachAirlineAlternative3) : null}
	    						</div>
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-plane"></span>
	    						<input type="text" className="anim1" maxLength={5} ref="flightNumberAlternative3" name="flightNumberAlternative3" value={this.state.flightNumberAlternative3} onChange={this.onChangeNaN} placeholder="Flugnummer" />
	    					</div>
	    					<div className="inputCtr">
	    						<span className="fas fa-calendar"></span>
	    						<DateTime
	    							value={moment(this.state.flightDateAlternative3, "DD.MM.YYYY")}
	    							isValidDate={this.state.isValidOtherDate}
	    							dateFormat={'DD.MM.YYYY'}
		    						closeOnSelect={true}
		    						inputProps={{ placeholder: 'Ersatzflugdatum', className: 'anim1', name: 'flightDateAlternative3', readOnly: true }}
		    						timeFormat={false}
		    						onChange={this.onChangeDateAlternative3}
		    					/>
	    					</div>
	    					<button className={'cta1 anim1'} onClick={() => this.jumpStep(this.state.flightNumberAlternative3 === '' || this.state.flightDateAlternative3 === '' || this.state.selectedAirlineAlternative3 === '' ? 12 : 11)}>{this.state.flightNumberAlternative3 === '' || this.state.flightDateAlternative3 === '' || this.state.selectedAirlineAlternative3 === '' ? 'Weiter ohne Ersatzflug' : 'Weiter'}</button>
    					</div>
    				</div>
    				{/* STEP 11 */}
    				<div id="step11" className={'anim2 step' + (this.state.currentStep === 11 ? ' visible' : '')}>
    					<h2>Handelt es sich um diesen Flug?</h2>
    					<div className="flightInfo">
							<p className="airlineFlight" style={{ paddingBottom: 0 }}>{this.state.flightCarrierAlternative3 + ' - ' + this.state.flightIataCodeAlternative3 + this.state.flightNumberAlternative3}</p>
						</div>
    					<button className="cta1 anim1" onClick={() => this.jumpStep(12)}>Weiter</button>
    				</div>
    				{/* STEP 12 */}
    				<div id="step12" className={'anim2 step' + (this.state.currentStep === 12 ? ' visible' : '')}>
    					<h2>Welcher Grund wurde Ihnen für die Verspätung genannt?</h2>
    					<div className="options" style={{ padding: '0 140px' }}>
	    					<button className={'anim1 option' + (this.state.delayReason === 'Technik' ? ' selected' : '')} onClick={() => this.selectDelayReason('Technik')}><span className="anim1 fas fa-wrench"></span> Technik</button>
	    					<button className={'anim1 option' + (this.state.delayReason === 'Crew' ? ' selected' : '')} onClick={() => this.selectDelayReason('Crew')}><span className="anim1 fas fa-user-tie"></span> Crew</button>
	    					<button className={'anim1 option' + (this.state.delayReason === 'Streik' ? ' selected' : '')} onClick={() => this.selectDelayReason('Streik')}><span className="anim1 fas fa-users-cog"></span> Streik</button>
    					</div>
						<div className="options" style={{ padding: '0 140px' }}>
							<button className={'anim1 option' + (this.state.delayReason === 'Wetter' ? ' selected' : '')} onClick={() => this.selectDelayReason('Wetter')}><span className="anim1 fas fa-umbrella"></span> Wetter</button>
	    					<button className={'anim1 option' + (this.state.delayReason === 'Anderer' ? ' selected' : '')} onClick={() => this.selectDelayReason('Anderer')}><span className="anim1 fas fa-question-circle"></span> Anderer</button>
	    					<button className={'anim1 option' + (this.state.delayReason === 'Kein Grund genannt' ? ' selected' : '')} onClick={() => this.selectDelayReason('Kein Grund genannt')}><span className="anim1 fas fa-comment-slash"></span> Kein Grund genannt</button>
    					</div>
    					<textarea className="anim1" name="delayInformationField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.delayInformationField} readOnly={this.state.delayReason === 'Kein Grund genannt' || this.state.delayReason === 'Wetter' ? true : false}></textarea>
    					<div>
							{this.state.delayReason === 'Wetter' ? <LinkButton className="cta1 anim1" to="/">Neue Anfrage</LinkButton> : <button className={'cta1 anim1' + (this.state.delayReason === 'Kein Grund genannt' ? '' : this.state.delayReason === '' || this.state.delayInformationField === '' || this.state.delayReason === 'Wetter' ? ' disabled' : '')} disabled={(this.state.delayReason === 'Kein Grund genannt' ? false : this.state.delayReason === '' || this.state.delayInformationField === '' || this.state.delayReason === 'Wetter' ? true : false)} onClick={() => this.jumpStep(13)}>Weiter</button>}
						</div>
    				</div>
    				{/* STEP 13 */}
    				<div id="step13" className={'anim2 step' + (this.state.currentStep === 13 ? ' visible' : '')}>
    					<h2>Haben Sie oder jemand anderes bereits selbst versucht von der Fluggesellschaft eine Entschädigung zu erhalten oder wurden Sie bereits entschädigt? *</h2>
						<h3 style={{ fontSize: 15 }}>* z.B. Gutschein oder anteilige Erstattung.</h3>
    					<div className="options" style={{ padding: '0 140px' }}>
	    					<button className={'anim1 option' + (this.state.contactedAirline === 'Ja' ? ' selected' : '')} onClick={() => this.selectContactedAirline('Ja')}><span className="anim1 fas fa-check"></span> Ja</button>
	    					<button className={'anim1 option' + (this.state.contactedAirline === 'Nein' ? ' selected' : '')} onClick={() => this.selectContactedAirline('Nein')}><span className="anim1 fas fa-times"></span> Nein</button>
    					</div>
    					<textarea className="anim1" name="contactedAirlineField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.contactedAirlineField}></textarea>
    					<div>
							<button className={'cta1 anim1' + (this.state.contactedAirline === '' ? ' disabled' : this.state.contactedAirline === 'Ja' && this.state.contactedAirlineField === '' ? ' disabled' : '')} disabled={(this.state.contactedAirline === '' ? true : this.state.contactedAirline === 'Ja' && this.state.contactedAirlineField === '' ? true : false)} onClick={() => this.jumpStep(14)}>Weiter</button>
						</div>
    				</div>
					{/* STEP 14 */}
    				<div id="step14" className={'anim2 step' + (this.state.currentStep === 14 ? ' visible' : '')}>
    					<h2>Sind Ihnen durch die Flugstörung zusätzliche Kosten entstanden? *</h2>
						<h3 style={{ fontSize: 15 }}>* z.B. Hotel-, Verpflegungs- oder Transferkosten.</h3>
    					<div className="options" style={{ padding: '0 140px' }}>
	    					<button className={'anim1 option' + (this.state.additionalCosts === 'Ja' ? ' selected' : '')} onClick={() => this.selectAdditionalCosts('Ja')}><span className="anim1 fas fa-check"></span> Ja</button>
	    					<button className={'anim1 option' + (this.state.additionalCosts === 'Nein' ? ' selected' : '')} onClick={() => this.selectAdditionalCosts('Nein')}><span className="anim1 fas fa-times"></span> Nein</button>
    					</div>
    					<textarea className="anim1" name="additionalCostsField" onChange={this.onChange} placeholder="Bitte geben Sie uns noch detailliertere Informationen" value={this.state.additionalCostsField}></textarea>
    					<div>
							<button className={'cta1 anim1' + (this.state.additionalCosts === '' ? ' disabled' : this.state.additionalCosts === 'Ja' && this.state.additionalCostsField === '' ? ' disabled' : '')} disabled={(this.state.additionalCosts === '' ? true : this.state.additionalCosts === 'Ja' && this.state.additionalCostsField === '' ? true : false)} onClick={() => this.jumpStep(15)}>Weiter</button>
						</div>
    				</div>
    				{/* KONTAKT */}
    				{this.state.currentStep === 15 ? <KontaktProzess info={this.state} /> : null}
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default ManuelleEingabeAnnullierung;
