import React, { Component } from 'react';
import {
	Route,
	Switch
} from 'react-router-dom';
import Home from '../components/sections/Home';
import Kontakt from '../components/sections/Kontakt';
import EndeKontakt from '../components/sections/Ende';
import Verspaetung from '../components/sections/prozess/Verspaetung';
import Annullierung from '../components/sections/prozess/Annullierung';
import NichtMitfliegen from '../components/sections/prozess/NichtMitfliegen';
import AnschlussflugVerpasst from '../components/sections/prozess/AnschlussflugVerpasst';
import ManuelleEingabeVerspaetung from '../components/sections/prozess/ManuelleEingabeVerspaetung';
import ManuelleEingabeAnnullierung from '../components/sections/prozess/ManuelleEingabeAnnullierung';
import ManuelleEingabeAnschlussflugVerpasst from '../components/sections/prozess/ManuelleEingabeAnschlussflugVerpasst';
import ManuelleEingabeNichtMitfliegen from '../components/sections/prozess/ManuelleEingabeNichtMitfliegen';
import EndeProzess from '../components/sections/prozess/Ende';
import Datenschutz from '../components/sections/Datenschutz';
import Impressum from '../components/sections/Impressum';
import AGB from '../components/sections/AGB';
import Unterlagen from '../components/sections/Unterlagen';
import UnterlagenEnde from '../components/sections/UnterlagenEnde';
import NotFound from '../components/system/NotFound';
import Gepaeck from '../components/sections/Gepaeck';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import LogoWhatsapp from '../assets/logo-whatsapp.jpg';
import BankInformationen from '../components/sections/BankInformationen';
import BankInformationenEnde from '../components/sections/BankInformationenEnde';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../constants/server';

// Connect to Directus
const directus = new Directus(apiUrl);

class AppRouter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			settings: {}
		}
	}

	async componentDidMount() {
		// Get settings
		const settings = await directus.items('settings').readByQuery({
			fields: ['show_whatsapp_button'],
		});
		this.setState({ settings: settings.data });
	}

    render() {
		return(
			<div>
				{/* Whatsapp */}
				{!this.state.settings || !this.state.settings.show_whatsapp_button ?
					<></>
				:
					<FloatingWhatsApp
						phoneNumber="+4961023527460"
						accountName="FLUG. CLAIM"
						avatar={LogoWhatsapp}
						statusMessage="Antwortet in der Regel innerhalb einer Stunde"
						chatMessage="Wie können wir Ihnen heute helfen?"
					/>
				}
            	<Switch>
    				<Route exact path="/" component={Home} />
    				<Route exact path="/kontakt" component={Kontakt} />
                    <Route exact path="/ende" component={EndeKontakt} />
    				<Route exact path="/prozess/verspaetung" component={Verspaetung} />
    				<Route exact path="/prozess/annullierung" component={Annullierung} />
    				<Route exact path="/prozess/nicht-mitfliegen" component={NichtMitfliegen} />
                    <Route exact path="/prozess/anschlussflug-verpasst" component={AnschlussflugVerpasst} />
                    <Route exact path="/prozess/manuelle-eingabe-verspaetung" component={ManuelleEingabeVerspaetung} />
                    <Route exact path="/prozess/manuelle-eingabe-annullierung" component={ManuelleEingabeAnnullierung} />
                    <Route exact path="/prozess/manuelle-eingabe-anschlussflug-verpasst" component={ManuelleEingabeAnschlussflugVerpasst} />
					<Route exact path="/prozess/gepaeck" component={Gepaeck} />
                    <Route exact path="/prozess/manuelle-eingabe-nicht-mitfliegen" component={ManuelleEingabeNichtMitfliegen} />
                    <Route exact path="/prozess/ende" component={EndeProzess} />
    				<Route exact path="/datenschutz" component={Datenschutz} />
    				<Route exact path="/impressum" component={Impressum} />
    				<Route exact path="/agb" component={AGB} />
                    <Route exact path="/unterlagen" component={Unterlagen} />
                    <Route exact path="/unterlagen/ende" component={UnterlagenEnde} />
					<Route exact path="/bank-informationen" component={BankInformationen} />
                    <Route exact path="/bank-informationen/ende" component={BankInformationenEnde} />
	    			<Route exact path="*" component={NotFound} status={404} />
    			</Switch>
    		</div>
        );
    }
}

export default AppRouter;
