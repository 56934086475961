import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import imgSection1 from '../../assets/section-1.jpg';
import imgSection2 from '../../assets/section-2.jpg';
import imgSection3 from '../../assets/section-3.jpg';
import imgSection4 from '../../assets/section-4.jpg';
import imgFlighCompensation from '../../assets/banner-flight-info.png';
import imgFlighCompensationMobile from '../../assets/banner-flight-info-mobile.png';
import { Link } from 'react-router-dom';
import nl2br from 'react-nl2br';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep: 0,
			windowWidth: 0,
			windowHeight: 0,
			airlinesList: {},
		}
	}

	async componentDidMount() {

		// Window resizing
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);

		// Connect to Directus
		const directus = new Directus(apiUrl);

		// Get list
		const airlinesList  = await directus.items('home_airlines_list').readByQuery({ fields: ['*'] });

		// Assign
		this.setState({
			airlinesList: airlinesList.data
		});

	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});
	}

	jumpStep = (step) => {
		this.setState({
			currentStep: step
		});
	}

    render() {
    	return(
    		<div>
    			<Helmet>
					<title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Home</title>
					<meta name="fragment" content="!" />
					<meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
					<meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
				</Helmet>
    			<Header />
    			<div id="process" style={{ maxHeight: 650 }}>
    				{/* INITIAL */}
    				<div id="initialStep" className={'anim2 step' + (this.state.currentStep === 0 ? ' visible' : '')}>
    					<h1>PROBLEME MIT DEM FLUG GEHABT?</h1>
    					<p style={{ fontSize: 26, lineHeight: '36px', textAlign: 'center' }}>
    						Flug verspätet? | Flug annulliert? | Flug überbucht? | <strong>Bis zu 600 Euro Entschädigung! *</strong> <br />
    						<span style={{ fontSize: 13 }}>* abzüglich unserer Provision in Höhe von 22,5% (+MwSt.).</span>
    					</p>
    					<button className="anim1" id="ctaHome" onClick={() => this.jumpStep(1)}>Jetzt kostenlos prüfen!</button>
    				</div>
    				{/* STEP 1 */}
    				<div id="step1" className={'anim2 step' + (this.state.currentStep === 1 ? ' visible' : '')}>
    					<h2>Welches Problem hatten Sie?</h2>
    					<div className="options">
	    					<Link to="/prozess/verspaetung" className="anim1 option">Mein Flug war verspätet</Link>
	    					<Link to="/prozess/annullierung" className="anim1 option">Mein Flug wurde annulliert</Link>
	    					<Link to="/prozess/anschlussflug-verpasst" className="anim1 option">Ich habe meinen Anschlussflug verpasst</Link>
	    					<Link to="/prozess/nicht-mitfliegen" className="anim1 option">Ich durfte nicht mitfliegen</Link>
							<Link to="/prozess/gepaeck" className="anim1 option">Gepäck</Link>
    					</div>
    				</div>
    			</div>
    			<main>
    				<section id="wasMacht">
    					<div className="info">
    						<span className="fas fa-balance-scale"></span>
	    					<p><strong>FLUG. CLAIM</strong> ist seit 2017 als Rechtsdienstleister beim Oberlandesgericht Frankfurt/Main registriert und übernimmt die Vertretung von Reisenden gegenüber Fluggesellschaften und hilft ihnen dabei, berechtigte Ansprüche außergerichtlich als auch gerichtlich, durchzusetzen.<br /><br />
	    					Wir prüfen anhand von detaillierten Flug- und Wetteraufzeichnungen, ob Ihnen eine Entschädigung/Ausgleichszahlung auf Basis der Europäischen Fluggastrechte Verordnung 261/2004 zusteht.<br /><br />
	    					Falls ja, fordern wir bis zu 600€ von den Fluggesellschaften für Sie ein. Schadenersatzpflichtige Zusatzkosten wie z.B. Hotel-, Verpflegungs- oder Transferkosten, welche Ihnen entstanden sind, machen wir ebenfalls mit geltend. Unsere Experten haben zudem vollen Zugriff auf alle aktuellen Gerichtsurteile.<br /><br />
	    					<strong>Sie haben dabei kein Kostenrisiko!</strong> Nur wenn wir erfolgreich sind, zahlen Sie an uns eine Provision von lediglich 22,5% +MwSt. (=26,78% inkl. MwSt.). Haben wir keinen Erfolg, zahlen Sie nichts. Unsere Erfolgsquote vor Gericht liegt bei 98%.</p>
    					</div>
    					<div className="img" style={{ backgroundImage: 'url(' + imgSection1 + ')' }}>
    						<h2>Was macht FLUG. CLAIM?</h2>
    					</div>
    				</section>
    				<section id="wasSteht">
    					<div className="img" style={{ backgroundImage: 'url(' + imgSection2 + ')' }}>
    						<h2>Was steht mir wann zu?</h2>
    					</div>
    					<div className="info">
    						<span className="fas fa-clock"></span>
	    					<p>Ab einer Verspätung von <strong>mehr als 3 Stunden</strong> steht Ihnen auf Grundlage der Europäischen Fluggastrechte Verordnung 261/2004 eine finanzielle Entschädigung/Ausgleichszahlung zu, vorausgesetzt die Verspätung fällt in den Verantwortungsbereich der Fluggesellschaft.<br /><br />
	    					Als Grundlage zur Berechnung der Verspätung ist immer die Ankunftszeit am endgültigen Zielort relevant. Nicht, wie oft irrtümlicherweise angenommen, die Abflugzeit.<br /><br />
	    					Als Ankunftszeit zählt, wenn am Zielort mindestens eine Tür des Flugzeuges geöffnet worden ist und die Passagiere durch diese, die Maschine auch verlassen.<br /><br />
	    					Die Höhe der Ausgleichszahlung richtet sich dann nach der tatsächlichen Verspätung sowie der Flugdistanz und beträgt <strong>250, 400 oder 600 Euro pro Person</strong>. Dabei ist es völlig irrelevant, wie viel Sie für Ihr Ticket bezahlt haben und ob Sie die Flüge individuell oder als Teil einer Pauschalreise (Reiseveranstalter) gebucht haben. Ansprüche können <strong>bis zu 3 Jahre rückwirkend ab Flugdatum</strong> geltend gemacht werden.</p>
    					</div>
    				</section>
    			</main>
    			<div style={{ backgroundColor: '#EEEEEE', padding: '50px 0' }}>
    				{this.state.windowWidth <= 645 ? 
    					<img src={imgFlighCompensationMobile} alt="WAS. CLAIM" style={{ width: '95%', height: 'auto', display: 'block', margin: '0 auto' }} />
    				:
    					<img src={imgFlighCompensation} alt="WAS. CLAIM" style={{ width: '95%', height: 'auto', display: 'block', margin: '0 auto' }} />
    				}
    			</div>
    			<main style={{ borderTop: '10px solid #CCCCCC', borderBottom: 0 }}>
    				<section id="wieFunktionierts">
    					<div className="info" style={{ paddingLeft: 50, paddingRight: 50 }}>
    						<div className="block">
								<p>
									<span>1</span>
									Flugdaten eingeben.
								</p>
								<p>Der kostenlose Entschädigungsrechner verrät Ihnen, ob Sie einen Anspruch auf Entschädigung haben.</p>
							</div>
							<div className="block">
								<p>
									<span>2</span>
									Wir beginnen unsere Arbeit.
								</p>
								<p>Beauftragen Sie uns, dann setzen unsere Reiserechtsexperten Ihre Ansprüche durch. Wenn erforderlich auch vor Gericht.</p>
							</div>
							<div className="block">
								<p>
									<span>3</span>
									Ihr Geld ist da.
								</p>
								<p>Im Erfolgsfall zahlen wir Ihre Entschädigung umgehend an Sie aus. (abzüglich unserer Provision in Höhe von 22,5% + MwSt. bzw. 26,78% inkl. MwSt.)</p>
							</div>
    					</div>
    					<div className="img" style={{ backgroundImage: 'url(' + imgSection4 + ')' }}>
    						<h2>Wie funktioniert's?</h2>
    					</div>
    				</section>
    			</main>
				<main>
    				<section id="welcheAirlines">
    					<div className="img" style={{ backgroundImage: 'url(' + imgSection3 + ')' }}>
    						<h2>Welche Airlines?</h2>
    					</div>
						<div className="info">
    						<span className="fas fa-plane"></span>
	    					<p><strong>FLUG. CLAIM</strong> nimmt Forderungen bei Flugverspätung, Flugannullierung oder Flugüberbuchung gegen folgende Fluggesellschaften an:</p>
	    					<div className="tripleList">
								{/* First column */}
								{nl2br(this.state.airlinesList.first_column)}
    						</div>
    						<div className="tripleList">
								{/* Second column */}
								{nl2br(this.state.airlinesList.second_column)}
    						</div>
    						<div className="tripleList">
								{/* Third column */}
								{nl2br(this.state.airlinesList.third_column)}
    						</div>
    					</div>
    				</section>
    			</main>
    			<div id="info" className="sectionIcons">
					<div className="row">
						<Link className="col-4 col-md-12 info md-noPadding" style={{ cursor: 'pointer' }} to="/prozess/verspaetung">
							<span className="anim1 fas fa-user-clock"></span>
							<h3>VERSPÄTUNG</h3>
							<p>
								<strong>Ihr Flug war verspätet?</strong> Dann haben Sie während der Wartezeit Anspruch auf eine angemessene Betreuungsleistung, bestehend aus kostenlosen Getränken und Mahlzeiten. <strong>Ab 3 Stunden steht Ihnen eine Entschädigung zu!</strong> Die Fluggesellschaft muss die Entschädigung an jeden Passagier auszahlen, auf den ein Ticket ausgestellt wurde. Grundsätzlich haben Babys unter 2 Jahren daher keinen Anspruch, es sei denn, für die Buchung wurde eine „Gebühr“ berechnet. Die Auszahlung muss in Form von Geld erfolgen. Gutscheine und Prämienmeilen zählen nicht als Ausgleichszahlung.
							</p>
						</Link>
						<Link className="col-4 col-md-12 info" style={{ cursor: 'pointer' }} to="/prozess/annullierung">
							<span className="anim1 fas fa-times-circle"></span>
							<h3>ANNULLIERUNG</h3>
							<p>
								<strong>Ihr Flug wurde gestrichen?</strong> Waren Sie bereits am Flughafen, dann haben Sie Anspruch auf eine angemessene Betreuungsleistung, bestehend aus kostenlosen Getränken, Mahlzeiten und ggf. einem Hotelaufenthalt. <strong>Kann Ihnen keine zeitnahe Ersatzbeförderung</strong> angeboten werden oder Sie erreichen mit dem Ersatzflug Ihr Ziel mit einer Verspätung von mindestens 2 Stunden, dann steht Ihnen die Ausgleichszahlung zu. <strong>Wurden Sie jedoch mindestens 14 Tage vor dem Abflugdatum über den Flugausfall informiert, steht Ihnen leider keine Entschädigung zu.</strong>
							</p>
						</Link>
						<Link className="col-4 col-md-12 info" style={{ cursor: 'pointer' }} to="/prozess/anschlussflug-verpasst">
							<span className="anim1 fas fa-angle-double-right"></span>
							<h3>ANSCHLUSSFLÜGE</h3>
							<p>
								<strong>Sie haben Ihren Anschlussflug verpasst?</strong> Wenn Ihr Zubringerflug Verspätung hatte, Sie dadurch Ihren Anschlussflug verpasst haben und letztendlich an Ihrem endgültigen Zielort mit einer Verspätung von <strong>mehr als 3 Stunden</strong> angekommen sind, steht Ihnen eine Entschädigung zu. Relevant ist hierbei die Verspätung am tatsächlichen Zielort und nicht - wie oft irrtümlicher Weise angenommen - die Verspätung der einzelnen Teilstrecken.
							</p>
						</Link>
					</div>
					<div className="row md-noPadding" style={{ paddingTop: 50 }}>
						<Link className="col-4 col-md-12 info" style={{ cursor: 'pointer' }} to="/prozess/nicht-mitfliegen">
							<span className="anim1 fas fa-user-times"></span>
							<h3>NICHTBEFÖRDERUNG</h3>
							<p>
								<strong>Sie wurden nicht mitgenommen?</strong> War Ihr Flug überbucht oder Sie wurden aus einem anderen Grund nicht befördert und die Fluggesellschaft kann nicht ausreichend begründen, warum man Sie nicht mitgenommen hat, dann steht Ihnen eine Entschädigung zu. Dies gilt jedoch nicht bei sicherheitsrelevanten und gesundheitlichen Gründen oder unvollständigen Reiseunterlagen.
							</p>
						</Link>
						<div className="col-4 col-md-12 info">
							<span className="anim1 fas fa-shopping-bag"></span>
							<h3>VORVERLEGUNG</h3>
							<p>
								<strong>Ihr Flug hat um mehr als 1 Stunde früher stattgefunden als wie geplant?</strong> Wurde Ihr ursprünglich gebuchter Flug um mehr als eine Stunde nach vorne verlegt, kommt dies einer Annullierung des Fluges gleich, so die aktuelle Rechtsprechung. Ihnen steht hierfür somit eine Entschädigung zu. <strong>Wurden Sie jedoch mindestens 14 Tage vor dem Abflugdatum über die Flugzeitenänderung informiert, steht Ihnen leider keine Ausgleichszahlung zu.</strong>
							</p>
						</div>
						<div className="col-4 col-md-12 info">
							<span className="anim1 fas fa-snowflake"></span>
							<h3>WINTER / ENTEISUNG</h3>
							<p>
								<strong>Ihr Flug war aufgrund von Enteisung verspätet?</strong> Das Enteisen eines Flugzeuges gehört zu den regulären Pflichten und Aufgaben einer Fluggesellschaft und muss somit in die Planung der Winterflüge (Flugzeiten) mit einbezogen werden. Hatten Sie aufgrund von Frost und Eis eine Verspätung von mehr als 3 Stunden, dann steht Ihnen eine Entschädigung zu.
							</p>
						</div>
					</div>
					<div className="row md-noPadding" style={{ paddingTop: 50 }}>
						<div className="col-4 col-md-12 info">
							<span className="anim1 fas fa-map-signs"></span>
							<h3>STREIK</h3>
							<p>
								Grundsätzlich gehören Streiks zu den sogenannten „Außergewöhnlichen Umständen“, da sie nicht in den Verantwortungsbereich der Fluggesellschaft fallen. <strong>Dies gilt jedoch nicht, wenn es sich bei den Streikenden um eigenes Personal der Fluggesellschaft handelt</strong>, wie z.B. Cockpit oder Kabinen Crews. In diesem Fall haben Sie Anspruch auf Entschädigung.
							</p>
						</div>
						<div className="col-4 col-md-12 info">
							<span className="anim1 fas fa-globe"></span>
							<h3>PAUSCHALREISE</h3>
							<p>
								<strong>Auch bei einer Pauschalreise steht Ihnen im Fall einer Flugverspätung oder Annullierung eine Entschädigung zu</strong>. Sollten Sie über einen Reiseveranstalter eine Pauschalreise gebucht haben und es kommt bei den Flügen zu Problemen, haben Sie gem. der europäischen Fluggastrechte Verordnung gegenüber der Fluggesellschaft das Recht auf Entschädigung. Darüber hinaus können Sie ggf. weitere Ansprüche gegen den Reiseveranstalter geltend machen, wie z.B. eine Reisepreisminderung. Hierbei ist jedoch zu beachten, dass beide Entschädigungssummen gegenseitig angerechnet werden können. 
							</p>
						</div>
						<div className="col-4 col-md-12 info hover">
							<span className="anim1 fas fa-exclamation-circle"></span>
							<h3>AUßERGEWÖHNLICHE UMSTÄNDE!</h3>
							<p>
								Kann die Fluggesellschaft als Grund für die Verspätung/Annullierung <strong>„außergewöhnliche Umstände“</strong> nachweisen und ist somit nicht für die Verspätung verantwortlich, muss sie keine Entschädigung zahlen. Dies können z.B. schlechte Wetterverhältnisse, Naturkatastrophen, Terrorwarnungen, Notlandungen aufgrund eines medizinischen Notfalls oder behördliche Vorgaben sein. Bei Streiks kommt es hingegen darauf an, wer streikt. Legen z.B. Fluglotsen und Angestellte des Flughafens ihre Arbeit nieder, liegt dies nicht im Verantwortungsbereich der Fluggesellschaft.
							</p>
						</div>
					</div>
				</div>
				<Footer />
    		</div>
    	);
    }
}

export default Home;
