import React, { Component } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Logo from '../../assets/main-logo-flug.png';
import ReactGA from 'react-ga';
import Ticker from 'react-ticker';
import { Directus } from '@directus/sdk';
import { apiUrl } from '../../constants/server';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuVisible: false,
			showBanner: false,
			tickerText: '',
        }
    }

    toggleMobileMenu = () => {
        this.setState({
            menuVisible: !this.state.menuVisible
        });
    }

    componentDidMount = async () => {
        window.addEventListener('scroll', this.changeHeaderClass);
        ReactGA.initialize('UA-129041788-1');
        ReactGA.pageview(this.props.location.pathname);

		// Connect to Directus
		const directus = new Directus(apiUrl);

		// Get ticker information
		const tickerInfo  = await directus.items('news').readByQuery({ fields: ['show_banner', 'text'] });

		// Assign
		this.setState({
			showBanner: tickerInfo.data.show_banner,
			tickerText: tickerInfo.data.text,
		});
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
            ReactGA.pageview(this.props.location.pathname);
        }
    }

    render() {
        const checkActive = (match, location) => {
            if(!location) return false;
            const {pathname} = location;
            return pathname === "/";
        }
        
    	return(
			<>
				{/* Ticker */}
				{this.state.showBanner ? 
					<Ticker speed={4}>
						{() => <h1 className="tickerText">{this.state.tickerText}</h1>}
					</Ticker>
				: null}
				{/* Header */}
				<header className="mainHeader">
					<div className="row">
						<div className="col-7 col-md-6">
							{/* Logo WAS */}
							<Link to="/">
								<img id="mainLogo" src={Logo} alt="WAS. CLAIM logo" />
							</Link>
							{this.props.section ? <h6>{this.props.section}</h6> : null}
						</div>
						<div className="col-5 col-md-6 ar">
							<ul id="mainMenu">
								<li><NavLink to="/" className="anim1" isActive={checkActive}>HOME</NavLink></li>
								<li><NavLink to="/datenschutz" className="anim1">DATENSCHUTZ</NavLink></li>
								<li><NavLink to="/agb" className="anim1">AGB</NavLink></li>
								<li><NavLink to="/kontakt" className="anim1">KONTAKT</NavLink></li>
							</ul>
							<span id="mobileMenuActivator" className="fas fa-bars" onClick={this.toggleMobileMenu}></span>
							<ul id="mobileMenu" className={'anim1' + (this.state.menuVisible ? ' visible' : '')}>
								<li><NavLink to="/" className="anim1" isActive={checkActive}>HOME</NavLink></li>
								<li><NavLink to="/datenschutz" className="anim1">DATENSCHUTZ</NavLink></li>
								<li><NavLink to="/agb" className="anim1">AGB</NavLink></li>
								<li><NavLink to="/kontakt" className="anim1">KONTAKT</NavLink></li>
							</ul>
						</div>
					</div>
				</header>
			</>
    	);
    }
}

export default withRouter(Header);
