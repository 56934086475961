import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Certificate from '../../assets/certificate.png';
import Certificate3 from '../../assets/certificate-3.jpg';
import Certificate5 from '../../assets/certificate-5.jpeg';
import Certificate6 from '../../assets/certificate-6.png';
import GoogleTagManager from '../system/GoogleTagManager';
import LogoWASInkasso from '../../assets/logo-was-inkasso.png';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCookieDisclaimer: true
        }
    }

    componentDidMount() {
        if(localStorage.showCookieDisclaimer === "false") {
            this.setState({
                showCookieDisclaimer: false
            });
        } else {
            this.setState({
                showCookieDisclaimer: true
            });
		}
    }

    acceptCookieDisclaimer = () => {
        localStorage.showCookieDisclaimer = "false";
        this.setState({
            showCookieDisclaimer: false
        });
    }

    render() {
    	return(
    		<footer className={this.props.className}>
                {this.state.showCookieDisclaimer ?
                    <div id="cookieDisclaimer">
                        <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Bitte beachten Sie hierzu unsere <Link to="/datenschutz">Datenschutzerklärung</Link>.</p>
                        <button className="anim1" onClick={this.acceptCookieDisclaimer}>OK</button>
                    </div>
                : null}
    			<div className="row">
    				<div className="col-5 col-l-4 col-m-6 col-s-12 s-ac s-padding">
						<img className="s-display" style={{ width: 'auto', maxHeight: 40, verticalAlign: 'middle', marginLeft: 25, mixBlendMode: 'multiply' }} src={Certificate5} alt="DSGVO Datenschutz Grundverordnung" />
						<img className="s-display" style={{ width: 'auto', maxHeight: 40, verticalAlign: 'middle', mixBlendMode: 'multiply', marginLeft: 25 }} src={Certificate6} alt="Logo Let's Encrypt" />
    					{/* <img className="s-display" style={{ width: 'auto', maxHeight: 40, verticalAlign: 'middle', marginLeft: 25 }} src={Certificate} alt="Sachverständiger des Deutscher Gutachter & Sachverständigen Verband e.V." /> */}
						<a href="https://www.dgfr.de/" target="_blank">
							<img className="s-display" style={{ width: 'auto', maxHeight: 40, verticalAlign: 'middle', mixBlendMode: 'multiply', marginLeft: 25 }} src={Certificate3} alt="Mitglied der DGfR Deutsche Gesellschaft für Reiserecht e.V." border="0" />
						</a>
						<a href="https://www.was-inkasso.de" target="_blank">
							<img className="s-display" style={{ width: 'auto', maxHeight: 40, verticalAlign: 'middle', marginLeft: 25, maxWidth: 150 }} src={LogoWASInkasso} alt="WAS. Inkasso" border="0" />
						</a>
    				</div>
    				<div className="col-2 col-l-3 col-m-6 col-s-12 ac s-padding" style={{ paddingTop: 5 }}>© {(new Date().getFullYear())} FLUG. CLAIM</div>
    				<div className="col-5 col-l-5 col-m-12 m-ac ar s-padding" style={{ paddingTop: 0 }}>
    					<ul id="footerMenu">
                            <li><a className="anim1 hoverBlue" href="https://www.facebook.com/WASCLAIM/" target="_blank" rel="noopener noreferrer" style={{ fontSize: 30 }}><span className="fab fa-facebook-square"></span></a></li>
                            <li style={{ marginLeft: 25 }}><a className="anim1 hoverPurple" href="https://www.instagram.com/flug.claim/" target="_blank" rel="noopener noreferrer" style={{ fontSize: 30 }}><span className="fab fa-instagram"></span></a></li>
    						<li style={{ marginLeft: 25 }}><Link className="anim1" to="/">FLUGENTSCHÄDIGUNG</Link></li>
    						<li><Link className="anim1" to="/impressum">IMPRESSUM</Link></li>
    					</ul>
    				</div>
    			</div>
                <GoogleTagManager gtmId='GTM-W7XDFWJ' />
    		</footer>
    	);
    }
}

export default Footer;
