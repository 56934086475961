import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../structure/Header';
import Footer from '../structure/Footer';
import LinkButton from '../elements/LinkButton';

class Ende extends Component {
    render() {
    	return(
    		<div>
                <Helmet>
                    <title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Vielen Dank!</title>
                    <meta name="fragment" content="!" />
                    <meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
                    <meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
                </Helmet>
    			<Header section="Vielen Dank!" />
    			<div id="process">
    				{/* END MESSAGE */}
    				<div id="step1" className={'anim2 step visible'}>
    					<h1 style={{ textAlign: 'center', fontSize: 30 }}>Vielen Dank für Ihre Nachricht.</h1>
    					<p style={{ fontSize: 17, lineHeight: '24px', textAlign: 'center', color: '#EEEEEE' }}>
    						Bitte geben Sie uns für die Bearbeitung Ihres Anliegens etwas Zeit, wir kommen schnellstmöglich wieder auf Sie zurück. Derzeit benötigen wir hierfür ca. eine Woche. Je nach Aufkommen, kann es dennoch vorkommen, dass Ihre Anfrage etwas mehr Zeit beansprucht. Bitte haben Sie hierfür Verständnis. Vielen Dank.<br /><br />
    						Eingehende Nachrichten werden innerhalb unserer Servicezeiten, Montag bis Freitag zwischen 11:00 und 17:00 Uhr für Sie bearbeitet.
    					</p>
    					<LinkButton className="cta1 anim1" to="/">Home</LinkButton>
    				</div>
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default Ende;
