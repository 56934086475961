import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../structure/Header';
import Footer from '../../structure/Footer';
import LinkButton from '../../elements/LinkButton';

class Ende extends Component {
    render() {
    	return(
    		<div>
                <Helmet>
                    <title>FLUG. CLAIM | Flug verspätet? | Flug annulliert? | Flug überbucht? | Bis zu 600 Euro Entschädigung! - Vielen Dank!</title>
                    <meta name="fragment" content="!" />
                    <meta name="description" content="Wir verhelfen Ihnen zu Ihrer Entschädigung. Ab 3 Stunden Verspätung oder bei Flugausfall, bis zu 600 Euro! - Ohne Kostenrisiko - Nur 22,5% Provision bei Erfolg!. Jetzt Anspruch online prüfen." />
                    <meta name="keywords" content="was claim, flug verspätung, flug annullierung, flug überbuchung, 600 euro, entschädigung" />
                </Helmet>
    			<Header section="Vielen Dank!" />
    			<div id="process">
    				{/* END MESSAGE */}
    				<div id="step1" className={'anim2 step visible'}>
    					<h1 style={{ textAlign: 'center', fontSize: 30 }}>Ihre Claimanfrage wurde erfolgreich an uns übermittelt.</h1>
    					<p style={{ fontSize: 17, lineHeight: '24px', textAlign: 'center', color: '#EEEEEE' }}>
    						<strong>WAS. passiert nun?</strong> Wir überprüfen Ihre Flugdaten, sowie die eingereichten Reisedokumente nochmals detailliert, um u.a. außergewöhnliche Umstände ausschließen zu können. Je nach Fluggesellschaft kann es sein, dass wir von Ihnen noch weitere Unterlagen benötigen. Bitte geben Sie uns für die Bearbeitung Ihrer Anfrage etwas Zeit, wir kommen schnellstmöglich wieder auf Sie zurück. Derzeit dauert die Prüfung ca. 2 Wochen. Je nach Komplexität der Anfrage, kann es dennoch vorkommen, dass die Bearbeitung etwas mehr Zeit beansprucht. Bitte haben Sie hierfür Verständnis. Vielen Dank.<br /><br />
    						Eingehende Anfragen werden innerhalb unserer Servicezeiten, Montag bis Freitag zwischen 11:00 und 17:00 Uhr für Sie bearbeitet.<br /><br />
    						Bitte beachten Sie, dass die Höhe der Ihnen angezeigten Ausgleichszahlung eine erste unverbindliche Vorabauswertung ist und auf Basis der von Ihnen eingegebenen Daten und den in unserer Datenbank hinterlegten Informationen ermittelt wurde. Sollte sich jedoch bei der weiteren Prüfung herausstellen, dass „außergewöhnliche Umstände“ der Grund für die Störung Ihres Fluges waren, ist die Airline nicht verpflichtet eine Entschädigung zu zahlen.
    					</p>
    					<LinkButton className="cta1 anim1" to="/">Home</LinkButton>
    				</div>
    			</div>
				<Footer className="withBorder" />
    		</div>
    	);
    }
}

export default Ende;
