import React, { Component } from 'react';
import moment from 'moment';

class FlightInfo extends Component {
	convertMinutes = (mins) => {
		mins = Math.abs(mins);
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        h = h < 10 ? '0' + h : h;
        m = m < 10 ? '0' + m : m;
        return `${h}:${m}`;
    }

    render() {
    	return(
    		<div className="flightInfo">
				<p className="airlineFlight">{this.props.flightInfo.airline + ' - ' + this.props.flightNumber}</p>
				<div className="row">
					<div className="col-6 col-s-12">
						<span className="fas fa-plane" style={{ transform: 'rotate(-45deg)' }}></span>
						<p className="airport">{this.props.flightInfo.departureAirport.name + ' (' + this.props.flightInfo.departureAirport.fs + ')'}</p>
						<p className="flightTimes">{moment(this.props.flightInfo.flightInfo.departureDate.dateLocal).format('DD.MM.YYYY, HH:mm:ss') + ' Uhr'}</p>
					</div>
					<div className="col-6 col-s-12 s-marginTop">
						<span className="fas fa-plane" style={{ transform: 'rotate(45deg)' }}></span>
						<p className="airport">{this.props.flightInfo.arrivalAirport.name + ' (' + this.props.flightInfo.arrivalAirport.fs + ')'}</p>
						<p className="flightTimes">{moment(this.props.flightInfo.flightInfo.arrivalDate.dateLocal).format('DD.MM.YYYY, HH:mm:ss') + ' Uhr'}</p>
					</div>
				</div>
				{this.props.noTime || this.props.totalTime ? null : this.props.flightInfo.flightInfo.status === "C" || this.props.flightInfo.flightInfo.status === "U" ? <div className="delayInfo">Annulliert</div> : this.props.flightInfo.delays.arrivalGateDelayMinutes === undefined ? <div className="delayInfo" style={{ backgroundColor: '#47B749' }}>Pünktlich</div> : this.props.flightInfo.delays.arrivalGateDelayMinutes < 5 ? <div className="delayInfo" style={{ backgroundColor: '#47B749' }}>Verzögert um {this.convertMinutes(this.props.flightInfo.delays.arrivalGateDelayMinutes)}</div> : <div className="delayInfo">Verzögert um {this.convertMinutes(this.props.flightInfo.delays.arrivalGateDelayMinutes)}</div>}
				{this.props.totalTime ? <div className="delayInfo">Verspätung Gesamt {this.convertMinutes(this.props.flightInfo.delays.totalDelay)}</div> : null}
			</div>
    	);
    }
}

export default FlightInfo;
